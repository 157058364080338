import React, { Fragment, Component } from "react";
import Header from "../../components/Header/Header";
import Toastr from "toastr";
import Dropdown from "../../components/Dropdown/Dropdown";

class AOForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountTypeData: "",
      display1joint: false,
      display2joint: false,
      displayNRI: false,
      fields: {},
      political: [{ value: "Yes", id: 1 }, { value: "No", id: 2 }],
      politicalValue: "",
      political1Value: "",
      political2Value: "",
      errors: {
        name: false,
        networth: false,
        occupation: false,
        bplace: false,
        taxId: false,
        name1: false,
        networth1: false,
        occupation1: false,
        bplace1: false,
        taxId1: false,
        name2: false,
        networth2: false,
        occupation2: false,
        bplace2: false,
        taxId2: false,
        address1: false,
        address2: false,
        address3: false,
        address4: false,
        city: false,
        state: false,
        pincode: false,
        country: false
      }
    };

    this.handleNameKeyUp = this.onBlurHandler.bind(this, "name");
    this.handleNetworthKeyUp = this.onBlurHandler.bind(this, "networth");
    this.handleOccupationKeyUp = this.onBlurHandler.bind(this, "occupation");
    this.handlePlaceKeyUp = this.onBlurHandler.bind(this, "bplace");
    this.handleTaxIdKeyUp = this.onBlurHandler.bind(this, "taxId");

    this.handleName1KeyUp = this.onBlurHandler.bind(this, "name1");
    this.handleNetworth1KeyUp = this.onBlurHandler.bind(this, "networth1");
    this.handleOccupation1KeyUp = this.onBlurHandler.bind(this, "occupation1");
    this.handlePlace1KeyUp = this.onBlurHandler.bind(this, "bplace1");
    this.handleTaxId1KeyUp = this.onBlurHandler.bind(this, "taxId1");

    this.handleName2KeyUp = this.onBlurHandler.bind(this, "name2");
    this.handleNetworth2KeyUp = this.onBlurHandler.bind(this, "networth2");
    this.handleOccupation2KeyUp = this.onBlurHandler.bind(this, "occupation2");
    this.handlePlace2KeyUp = this.onBlurHandler.bind(this, "bplace2");
    this.handleTaxId2KeyUp = this.onBlurHandler.bind(this, "taxId2");

    this.handleAddress1KeyUp = this.onBlurHandler.bind(this, "address1");
    this.handleAddress2KeyUp = this.onBlurHandler.bind(this, "address2");
    this.handleAddress3KeyUp = this.onBlurHandler.bind(this, "address3");
    this.handleAddress4KeyUp = this.onBlurHandler.bind(this, "address4");
    this.handleCityKeyUp = this.onBlurHandler.bind(this, "city");
    this.handleStateKeyUp = this.onBlurHandler.bind(this, "state");
    this.handleCountryKeyUp = this.onBlurHandler.bind(this, "country");
    this.handlePincodeKeyUp = this.onBlurHandler.bind(this, "pincode");
  }

  componentWillMount = () => {
    switch (this.state.accountTypeData.joint) {
      case "1":
        this.setState({ display1joint: true }, function() {});
        break;
      case "2":
        this.setState({ display2joint: true }, function() {});
        break;
      default:
        this.setState({ display1joint: false });
        this.setState({ display2joint: false });
        break;
    }
    switch (this.state.accountTypeData.investorType) {
      case "2":
        this.setState({ displayNRI: true }, function() {});
        break;
      default:
        this.setState({ displayNRI: false });
        break;
    }
  };

  onBlurHandler =refName => e => {
    console.log(refName);
    const x = e.target.value;
    console.log();
    if (refName === "name" || refName === "name1" || refName === "name2") {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid Name is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (
      refName === "networth" ||
      refName === "networth1" ||
      refName === "networth2"
    ) {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid Networth is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (
      refName === "occupation" ||
      refName === "occupation1" ||
      refName === "occupation2"
    ) {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid occupation is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (
      refName === "bplace" ||
      refName === "bplace1" ||
      refName === "bplace2"
    ) {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid Birth Place is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (refName === "taxId" || refName === "taxId1" || refName === "taxId2") {
      if (!x.match(/^[0-9 ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid TaxId is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (
      refName === "address1" ||
      refName === "address2" ||
      refName === "address3" ||
      refName === "address4"
    ) {
      if (!x.match(/^[a-zA-Z0-9 ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid " + [refName] + " is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (refName === "city" || refName === "state" || refName === "country") {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid " + [refName] + " is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (refName === "pincode") {
      if (!x.match(/^\d{6}$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid pincode is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
  }

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "Valid name is required.";
        Toastr.error("Valid name is required.");
      }
    }
    if (typeof fields["networth"] !== "undefined") {
      if (!fields["networth"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["networth"] = "Valid networth is required.";
        Toastr.error("Valid networth is required.");
      }
    }
    if (typeof fields["occupation"] !== "undefined") {
      if (!fields["occupation"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["occupation"] = "Valid occupation is required.";
        Toastr.error("Valid occupation is required.");
      }
    }
    if (typeof fields["bplace"] !== "undefined") {
      if (!fields["bplace"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["bplace"] = "Valid bplace is required.";
        Toastr.error("Valid bplace is required.");
      }
    }
    if (typeof fields["taxId"] !== "undefined") {
      if (!fields["taxId"].match(/^[0-9]*$/)) {
        formIsValid = false;
        errors["taxId"] = "Valid taxId is required.";
        Toastr.error("Valid taxId is required.");
      }
    }

    if (typeof fields["address1"] !== "undefined") {
      if (!fields["address1"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["address1"] = "Valid address1 is required.";
        Toastr.error("Valid address1 is required.");
      }
    }
    if (typeof fields["address2"] !== "undefined") {
      if (!fields["address2"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["address2"] = "Valid address2 is required.";
        Toastr.error("Valid address2 is required.");
      }
    }
    if (typeof fields["address3"] !== "undefined") {
      if (!fields["address3"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["address3"] = "Valid address3 is required.";
        Toastr.error("Valid address3 is required.");
      }
    }
    if (typeof fields["address4"] !== "undefined") {
      if (!fields["address4"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["address4"] = "Valid address4 is required.";
        Toastr.error("Valid address4 is required.");
      }
    }
    if (typeof fields["pincode"] !== "undefined") {
      if (!fields["pincode"].match(/^\d{6}$/)) {
        formIsValid = false;
        errors["pincode"] = "Valid pincode is required.";
        Toastr.error("Valid pincode is required.");
      }
    }
    if (typeof fields["city"] !== "undefined") {
      if (!fields["city"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["city"] = "Valid city is required.";
        Toastr.error("Valid city is required.");
      }
    }
    if (typeof fields["state"] !== "undefined") {
      if (!fields["state"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["state"] = "Valid state is required.";
        Toastr.error("Valid state is required.");
      }
    }
    if (typeof fields["country"] !== "undefined") {
      if (!fields["country"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["country"] = "Valid country is required.";
        Toastr.error("Valid country is required.");
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange = field => e => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      event.target.className += " needs-validation";
      const data = {
        addressLine1: this.state.fields["address1"],
        addressLine2: this.state.fields["address2"],
        addressLine3: this.state.fields["address3"],
        addressLine4: this.state.fields["address4"],
        city: this.state.fields["city"],
        state: this.state.fields["state"],
        pincode: this.state.fields["pincode"],
        country: this.state.fields["country"]
      };
      console.log(JSON.stringify(data));
    } else {
      event.target.className += " was-validated";
      Toastr.error("Form has errors.", "Form has errors.");
    }
  }

  onPoliticalClick = event => {
    const value = event.target.value;
    this.setState({ politicalValue: value }, () => {
      alert(value);
    });
  };

  onPolitical1Click = event => {
    const value = event.target.value;
    this.setState({ political1Value: value }, () => {
      alert(value);
    });
  };

  onPolitical2Click = event => {
    const value = event.target.value;
    this.setState({ political2Value: value }, () => {
      alert(value);
    });
  };

  render() {
    let secondApplicant, thirdApplicant, NRIInvestor;
    if (this.state.display1joint) {
      secondApplicant = (
        <div id="secondApplicant">
          <hr />
          <h4>Second Applicant</h4>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="name1"
                  id="name1"
                  required
                  MaxLength="25"
                  value={this.state.fields["name1"]}
                  className={this.state.errors.name1 ? "error" : "success"}
                  type="text"
                  ref="name1"
                  onBlur={this.handleName1KeyUp}
                  onChange={() => this.handleChange("name1")}
                />
                <label for="name1" className="form-label">
                  Name of the First Applicant
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="networth1"
                  id="networth1"
                  required
                  value={this.state.fields["networth1"]}
                  className={this.state.errors.networth1 ? "error" : "success"}
                  type="text"
                  ref="networth1"
                  onBlur={this.handleNetworth1KeyUp}
                  onChange={() => this.handleChange("networth1")}
                />
                <label for="networth1" className="form-label">
                  Networth
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="occupation1"
                  id="occupation1"
                  required
                  value={this.state.fields["occupation1"]}
                  className={
                    this.state.errors.occupation1 ? "error" : "success"
                  }
                  type="text"
                  ref="occupation1"
                  onBlur={this.handleOccupation1KeyUp}
                  onChange={() => this.handleChange("occupation1")}
                />
                <label for="occupation1" className="form-label">
                  Occupation Details
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="pbirth1"
                  id="pbirth1"
                  required
                  value={this.state.fields["pbirth1"]}
                  className={this.state.errors.pbirth1 ? "error" : "success"}
                  type="text"
                  ref="pbirth1"
                  onBlur={this.handlePlace1KeyUp}
                  onChange={() => this.handleChange("pbirth1")}
                />
                <label for="pbirth1" className="form-label">
                  Place of Birth
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group form-input">
                <Dropdown
                  onOptionClick={this.onPolitical1Click}
                  default={
                    "Politically exposed person / Related to Politically exposed person etc.?"
                  }
                  selected={this.state.political.id}
                  opts={this.state.political}
                  field_attr="id"
                  value_attr="value"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="taxId1"
                  id="taxId1"
                  required
                  value={this.state.fields["taxId1"]}
                  className={this.state.errors.taxId1 ? "error" : "success"}
                  type="text"
                  ref="taxId1"
                  onBlur={this.handleTaxId1KeyUp}
                  onChange={() => this.handleChange("taxId1")}
                />
                <label for="taxId1" className="form-label">
                  Tax ID No
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.display2joint) {
      secondApplicant = (
        <div id="secondApplicant">
          <hr />
          <h4>Second Applicant</h4>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="name1"
                  id="name1"
                  required
                  MaxLength="25"
                  value={this.state.fields["name1"]}
                  className={this.state.errors.name1 ? "error" : "success"}
                  type="text"
                  ref="name1"
                  onBlur={this.handleName1KeyUp}
                  onChange={() => this.handleChange("name1")}
                />
                <label for="name1" className="form-label">
                  Name of the First Applicant
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="networth1"
                  id="networth1"
                  required
                  value={this.state.fields["networth1"]}
                  className={this.state.errors.networth1 ? "error" : "success"}
                  type="text"
                  ref="networth1"
                  onBlur={this.handleNetworth1KeyUp}
                  onChange={() => this.handleChange("networth1")}
                />
                <label for="networth1" className="form-label">
                  Networth
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="occupation1"
                  id="occupation1"
                  required
                  value={this.state.fields["occupation1"]}
                  className={
                    this.state.errors.occupation1 ? "error" : "success"
                  }
                  type="text"
                  ref="occupation1"
                  onBlur={this.handleOccupation1KeyUp}
                  onChange={() => this.handleChange("occupation1")}
                />
                <label for="occupation1" className="form-label">
                  Occupation Details
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="pbirth1"
                  id="pbirth1"
                  required
                  value={this.state.fields["pbirth1"]}
                  className={this.state.errors.pbirth1 ? "error" : "success"}
                  type="text"
                  ref="pbirth1"
                  onBlur={this.handlePlace1KeyUp}
                  onChange={() => this.handleChange("pbirth1")}
                />
                <label for="pbirth1" className="form-label">
                  Place of Birth
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group form-input">
                <Dropdown
                  onOptionClick={this.onPolitical1Click}
                  default={
                    "Politically exposed person / Related to Politically exposed person etc.?"
                  }
                  selected={this.state.political.id}
                  opts={this.state.political}
                  field_attr="id"
                  value_attr="value"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="taxId1"
                  id="taxId1"
                  required
                  value={this.state.fields["taxId1"]}
                  className={this.state.errors.taxId1 ? "error" : "success"}
                  type="text"
                  ref="taxId1"
                  onBlur={this.handleTaxId1KeyUp}
                  onChange={() => this.handleChange("taxId1")}
                />
                <label for="taxId1" className="form-label">
                  Tax ID No
                </label>
              </div>
            </div>
          </div>
        </div>
      );
      thirdApplicant = (
        <div id="thirdApplicant" className="row-margin">
          <hr />
          <h4>Third Applicant</h4>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="name2"
                  id="name2"
                  required
                  MaxLength="25"
                  value={this.state.fields["name2"]}
                  className={this.state.errors.name2 ? "error" : "success"}
                  type="text"
                  ref="name2"
                  onBlur={this.handleName2KeyUp}
                  onChange={() => this.handleChange("name2")}
                />
                <label for="name2" className="form-label">
                  Name of the First Applicant
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="networth2"
                  id="networth2"
                  required
                  value={this.state.fields["networth2"]}
                  className={this.state.errors.networth2 ? "error" : "success"}
                  type="text"
                  ref="networth2"
                  onBlur={this.handleNetworth2KeyUp}
                  onChange={() => this.handleChange("networth2")}
                />
                <label for="networth2" className="form-label">
                  Networth
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="occupation2"
                  id="occupation2"
                  required
                  value={this.state.fields["occupation2"]}
                  className={
                    this.state.errors.occupation2 ? "error" : "success"
                  }
                  type="text"
                  ref="occupation2"
                  onBlur={this.handleOccupation2KeyUp}
                  onChange={() => this.handleChange("occupation2")}
                />
                <label for="occupation2" className="form-label">
                  Occupation Details
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="pbirth2"
                  id="pbirth2"
                  required
                  value={this.state.fields["pbirth2"]}
                  className={this.state.errors.pbirth2 ? "error" : "success"}
                  type="text"
                  ref="pbirth2"
                  onBlur={this.handlePlace2KeyUp}
                  onChange={() => this.handleChange("pbirth2")}
                />
                <label for="pbirth2" className="form-label">
                  Place of Birth
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group form-input">
                <Dropdown
                  onOptionClick={this.onPolitical2Click}
                  default={
                    "Politically exposed person / Related to Politically exposed person etc.?"
                  }
                  selected={this.state.political.id}
                  opts={this.state.political}
                  field_attr="id"
                  value_attr="value"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="taxId2"
                  id="taxId2"
                  required
                  value={this.state.fields["taxId2"]}
                  className={this.state.errors.taxId2 ? "error" : "success"}
                  type="text"
                  ref="taxId2"
                  onBlur={this.handleTaxId2KeyUp}
                  onChange={() => this.handleChange("taxId2")}
                />
                <label for="taxId2" className="form-label">
                  Tax ID No
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.displayNRI) {
      NRIInvestor = (
        <div id="NRIInvestor" className="row-margin">
          <h4>Overseas Address : (In case of NRI investor)</h4>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="address1"
                  id="address1"
                  MaxLength="25"
                  required
                  className={this.state.errors.address1 ? "error" : "success"}
                  value={this.state.fields["address1"]}
                  type="text"
                  ref="address1"
                  onBlur={this.handleAddress1KeyUp}
                  onChange={() => this.handleChange("address1")}
                />
                <label for="address1" className="form-label">
                  Address Line 1
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="address2"
                  id="address2"
                  required
                  MaxLength="25"
                  className={this.state.errors.address2 ? "error" : "success"}
                  value={this.state.fields["address2"]}
                  type="text"
                  ref="address2"
                  onBlur={this.handleAddress2KeyUp}
                  onChange={() => this.handleChange("address2")}
                />
                <label for="address2" className="form-label">
                  Address Line 2
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="address3"
                  id="address3"
                  MaxLength="25"
                  required
                  className={this.state.errors.address3 ? "error" : "success"}
                  value={this.state.fields["address3"]}
                  type="text"
                  ref="address3"
                  onBlur={this.handleAddress3KeyUp}
                  onChange={() => this.handleChange("address3")}
                />
                <label for="address3" className="form-label">
                  Address Line 3
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="address4"
                  id="address4"
                  MaxLength="25"
                  required
                  className={this.state.errors.address4 ? "error" : "success"}
                  value={this.state.fields["address4"]}
                  type="text"
                  ref="address4"
                  onBlur={this.handleAddress4KeyUp}
                  onChange={() => this.handleChange("address4")}
                />
                <label for="address4" className="form-label">
                  Address Line 4
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="city"
                  id="city"
                  required
                  MaxLength="15"
                  className={this.state.errors.city ? "error" : "success"}
                  value={this.state.fields["city"]}
                  type="text"
                  ref="city"
                  onBlur={this.handleCityKeyUp}
                  onChange={() => this.handleChange("city")}
                />
                <label for="city" className="form-label">
                  City
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="state"
                  id="state"
                  required
                  className={this.state.errors.state ? "error" : "success"}
                  value={this.state.fields["state"]}
                  type="text"
                  ref="state"
                  onBlur={this.handleStateKeyUp}
                  onChange={() => this.handleChange("state")}
                />
                <label for="state" className="form-label">
                  State
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="pincode"
                  id="pincode"
                  required
                  className={this.state.errors.pincode ? "error" : "success"}
                  value={this.state.fields["pincode"]}
                  type="text"
                  ref="pincode"
                  onBlur={this.handlePincodeKeyUp}
                  onChange={() => this.handleChange("pincode")}
                />
                <label for="pincode" className="form-label">
                  Pin Code
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="country"
                  id="country"
                  required
                  className={this.state.errors.country ? "error" : "success"}
                  value={this.state.fields["country"]}
                  type="text"
                  ref="country"
                  onBlur={this.handleCountryKeyUp}
                  onChange={() => this.handleChange("country")}
                />
                <label for="country" className="form-label">
                  Country
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <Fragment>
        <Header userRole="user" />
        <div className="col-md-12 col-offset-6 bg-lighter px-4 pt-2 padding-top-bottom">
          <form
            className="col-md-12 needs-validation"
            onSubmit={this.handleSubmit}
          >
            <div className="row">
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="name"
                    id="name"
                    required
                    MaxLength="25"
                    value={this.state.fields["name"]}
                    className={this.state.errors.name ? "error" : "success"}
                    type="text"
                    ref="name"
                    onBlur={this.handleNameKeyUp}
                    onChange={() => this.handleChange("name")}
                  />
                  <label for="name" className="form-label">
                    Name of the First Applicant
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="networth"
                    id="networth"
                    required
                    value={this.state.fields["networth"]}
                    className={this.state.errors.networth ? "error" : "success"}
                    type="text"
                    ref="networth"
                    onBlur={this.handleNetworthKeyUp}
                    onChange={() => this.handleChange("networth")}
                  />
                  <label for="networth" className="form-label">
                    Networth
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="occupation"
                    id="occupation"
                    required
                    value={this.state.fields["occupation"]}
                    className={
                      this.state.errors.occupation ? "error" : "success"
                    }
                    type="text"
                    ref="occupation"
                    onBlur={this.handleOccupationKeyUp}
                    onChange={() => this.handleChange("occupation")}
                  />
                  <label for="occupation" className="form-label">
                    Occupation Details
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="pbirth"
                    id="pbirth"
                    required
                    value={this.state.fields["pbirth"]}
                    className={this.state.errors.pbirth ? "error" : "success"}
                    type="text"
                    ref="pbirth"
                    onBlur={this.handlePlaceKeyUp}
                    onChange={() => this.handleChange("pbirth")}
                  />
                  <label for="pbirth" className="form-label">
                    Place of Birth
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group form-input">
                  <Dropdown
                    onOptionClick={this.onPoliticalClick}
                    default={
                      "Politically exposed person / Related to Politically exposed person etc.?"
                    }
                    selected={this.state.political.id}
                    opts={this.state.political}
                    field_attr="id"
                    value_attr="value"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="taxId"
                    id="taxId"
                    required
                    value={this.state.fields["taxId"]}
                    className={this.state.errors.taxId ? "error" : "success"}
                    type="text"
                    ref="taxId"
                    onBlur={this.handleTaxIdKeyUp}
                    onChange={() => this.handleChange("taxId")}
                  />
                  <label for="taxId" className="form-label">
                    Tax ID No
                  </label>
                </div>
              </div>
            </div>

            {NRIInvestor}

            {secondApplicant}

            {thirdApplicant}
            <div className="form-submit">
              <input
                type="submit"
                value="Next"
                className="btn btn-md mx-1 btn-warning px-5 button-width right"
                id="submit"
                name="submit"
              />
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default AOForm;
