import React, { Component, Fragment } from "react";
import Toastr from 'toastr';
import Button from '../../components/Button/Button';
import Select from 'react-select';
import { MUTUAL_FUNDS, ADDITIONAL_FUNDS } from '../../constants/constants';

// import actions
import { transactNow } from '../../actions/transactionActions';

class Purchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      server_error: '',
      mutualFunds: MUTUAL_FUNDS,
      selectedScheme: null,
      amount: '',
      scheme_error: '',
      amount_error: '',
      isActive: 'fresh',
      savedFundList: [],
      additionalFundList: ADDITIONAL_FUNDS,
      selectedIndex: null,
      isMoreFilter: false,
    };
  }

  addToCart = () => {
    Toastr.success('Added to cart', 'Cart');
  }

  validate = () => {
    let isError = false;
    const errors = {
      scheme_error: '',
      amount_error: '',
    };
    const { selectedScheme, amount } = this.state;

    if (selectedScheme == null || selectedScheme.length === 0) {
      isError = true;
      errors.scheme_error = "Scheme cannot be empty";
    }
    if (amount.length === 0) {
      isError = true;
      errors.amount_error = "Amount cannot be empty";
    }

    this.setState({
      ...this.state,
      ...errors
    });
    return isError;
  };

  resetForm = () => {
    this.setState({
      selectedScheme: null,
      amount: ''
    });
  };

  handleTransactNow = async () => {
    const err = this.validate();
    if (!err) {
      const scheme = {
        value: this.state.selectedScheme.value,
        amount: this.state.amount
      };

      try {
        const transactRes = await transactNow(scheme);
        const res = transactRes.data;
        console.log(res);
        Toastr.success('Transaction has been successfully.', 'Success');
        this.resetForm();
      } catch (err) {
        console.error('handleTransactNow, Server err: ', err.response);
        const data = err.response.data;
        this.setState({ server_error: data.error });
      }
    }
  };

  buyAnotherFund = () => {
    const err = this.validate();
    if (!err) {
      const selectedScheme = this.state.selectedScheme;
      const amount = this.state.amount;
      const fundList = this.state.savedFundList;
      fundList.push({ scheme: selectedScheme, amount: amount });
      this.setState({ savedFundList: fundList });
      this.resetForm();
    }
  }

  handleChangeScheme = (selectedScheme) => {
    this.setState({ selectedScheme });
    console.log(`Option selected:`, selectedScheme);
  }

  handleSelectedFund = (index) => {
    const additionalFundList = this.state.additionalFundList;
    const fund = additionalFundList[index];
    fund.isChecked = !fund.isChecked;
    this.setState({ additionalFundList });
  }


  render() {
    const {
      mutualFunds,
      selectedScheme,
      amount,
      scheme_error,
      amount_error,
      isActive,
      savedFundList,
      additionalFundList,
      // selectedIndex,
      isMoreFilter,
    } = this.state;

    return (
      <Fragment>
        <div className="container">
          <div className="al_card" id="al_purchase">
            <div className="row">
              <div className="al_scheme_tabs">
                <ul className="nav nav-fill">
                  <li className="nav-item" onClick={() => this.setState({ isActive: 'fresh' })}>
                    <span className={`nav-link ${isActive === 'fresh' ? 'active' : ''}`}>
                      Fresh
                    </span>
                  </li>
                  <li className="nav-item" onClick={() => this.setState({ isActive: 'additonal' })}>
                    <span className={`nav-link ${isActive === 'additonal' ? 'active' : ''}`}>
                      Additonal
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {
              isActive === 'fresh' ? (
                <div>
                  <div className="row mt-3">
                    <div className="col-md-9">
                      <div className="form-group">
                        <label htmlFor="scheme">Scheme:</label>
                        <Select
                          className="al_scheme_select"
                          name="scheme"
                          isClearable={true}
                          isSearchable={true}
                          placeholder="Select scheme..."
                          value={selectedScheme}
                          onChange={this.handleChangeScheme}
                          options={mutualFunds}
                        />
                        <div className="input-error">{scheme_error}</div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <span className="cursor" onClick={() => this.setState({ isMoreFilter: !this.state.isMoreFilter })}>
                      {
                        isMoreFilter ?
                          <i className="fa fa-minus-circle" aria-hidden="true"></i>
                          : <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      }
                      <span className="ml-1">More Filters</span>
                    </span>
                  </div>

                  {
                    isMoreFilter &&
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group has-search">
                          <label htmlFor="amc">Amc:</label>
                          <i className="fa fa-search form-control-search"></i>
                          <input type="text" className="form-control" id="amc" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group has-search">
                          <label htmlFor="asset_class">Asset Class:</label>
                          <i className="fa fa-search form-control-search"></i>
                          <input type="text" className="form-control" id="asset_class" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group has-search">
                          <label htmlFor="amc_scheme">Scheme:</label>
                          <i className="fa fa-search form-control-search"></i>
                          <input type="text" className="form-control" id="amc_scheme" />
                        </div>
                      </div>
                    </div>
                  }


                  <div className="row justify-content-center mt-3">
                    <div className="form-group form-inline mb-0">
                      <label htmlFor="amount">Amount:</label>
                      <div className="input-group ml-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text al_amount">Rs</div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="amount"
                          value={amount}
                          onChange={(e) => this.setState({ amount: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="input-error text-center">{amount_error}</div>
                  </div>


                  <div className="row justify-content-center mt-3">
                    <div className="mr-4">
                      <Button type="button" text="Buy Another Fund" onClick={this.buyAnotherFund} />
                    </div>
                    <Button type="button" text="Transact Now" onClick={this.handleTransactNow} />
                  </div>


                </div>
              ) : null
            }

            {
              isActive === 'additonal' ? (
                <div>
                  <div className="table-responsive mt-4">
                    <table className="table table-hover al_table">
                      <thead>
                        <tr>
                          <th scope="col">Scheme</th>
                          <th scope="col">Folio No</th>
                          <th scope="col">Units</th>
                          <th scope="col">Amount</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          additionalFundList.map((fund, index) => {
                            return (
                              <tr key={index}>
                                <td>{fund.scheme}</td>
                                <td>{fund.folioNo}</td>
                                <td>{fund.units}</td>
                                <td>{fund.amount}</td>
                                <td>
                                  <div style={{ width: '150px', display: 'flex' }}>
                                    <span style={{ height: '27px' }}>
                                      <Button
                                        type="button"
                                        text="Buy"
                                        size="sm"
                                        onClick={() => this.handleSelectedFund(index)}
                                      />
                                    </span>
                                    {/* <div style={{display: selectedIndex == index ? '' : 'none'}}> */}
                                    <input
                                      style={{ display: fund.isChecked ? '' : 'none', width: '130px', marginLeft: '5px' }}
                                      type="text"
                                      className="form-control"
                                      name="amount"
                                      placeholder="Amount"
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>


                  <div className="row justify-content-center mt-3">
                    <div className="mr-4">
                      <Button type="button" text="Buy Another Fund" />
                    </div>
                    <Button type="button" text="Transact Now" />
                  </div>
                </div>
              ) : null
            }

            {
              savedFundList.length !== 0 &&
              <div>
                <div className="table-responsive mt-4">
                  <table className="table table-hover al_table">
                    <thead>
                      <tr>
                        <th scope="col">Scheme</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        savedFundList.map((fund, index) => {
                          return (
                            <tr key={index}>
                              <td>{fund.scheme.label}</td>
                              <td>{fund.amount}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="mr-4">
                    <Button type="button" text="Add to Cart" />
                  </div>
                  <Button type="button" text="Submit Transactions" />
                </div>
              </div>
            }


          </div>
        </div>
      </Fragment>
    )
  }
};

export default Purchase;
