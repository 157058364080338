
import React, { Component, Fragment } from "react";
import Modal from 'react-bootstrap/Modal';
import Toastr from 'toastr';
import Select from 'react-select';
import Button from '../../components/Button/Button';
import { MUTUAL_FUNDS } from '../../constants/constants';

class ReedemSchemeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mutualFunds: MUTUAL_FUNDS,
      selectedScheme: null,
    };
  }

  addToCart = () => {
    Toastr.success('Added to cart', 'Cart');
    this.props.onHide();
  }

  handleChangeScheme = (selectedScheme) => {
    this.setState({ selectedScheme });
  }

  render() {
    const { mutualFunds, selectedScheme } = this.state;
    const { scheme } = this.props;
    return (
      <Fragment>
        <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
          <Modal.Header closeButton className="al_modal">
            <Modal.Title>Switch Scheme</Modal.Title>
          </Modal.Header>
          <Modal.Body className="al_modal_body">
            <div className="row">
              <label className="ml-3">Scheme: </label>
              <div className="al_scheme_text">{scheme.scheme_name}</div>
            </div>
            <div className="row mt-2">
              <label className="ml-3">Folio No: </label>
              <div className="al_scheme_text">{scheme.scheme_folio_no}</div>
            </div>
            <div className="row mt-2">
              <label className="ml-3">Units: </label>
              <div className="al_scheme_text">{scheme.unit_balance}</div>
            </div>
            <div className="row mt-2">
              <label className="ml-3">Current Value: </label>
              <div className="al_scheme_text">{scheme.current_value}</div>
            </div>

            <div className="row mt-3">
              <div className="col-md-9">
                <div className="form-group">
                  <label htmlFor="scheme">Select Scheme:</label>
                  <Select
                    className="al_scheme_select"
                    name="scheme"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Select scheme..."
                    value={selectedScheme}
                    onChange={this.handleChangeScheme}
                    options={mutualFunds}
                  />
                </div>
              </div>
            </div>

            <div className="row justify-content-end mt-5 pr-4">
              <Button
                type="button"
                text="Submit"
                onClick={this.props.onHide}
              />
            </div>

          </Modal.Body>
        </Modal>
      </Fragment>
    )
  }
};

export default ReedemSchemeModal;
