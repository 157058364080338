import React, { Component, Fragment } from "react";
import Header from '../../components/Header/Header';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }



  render() {
    return (
      <Fragment>
        <Header userRole="user" />
        <div className="container app_layout" id="dashboard">

          WIP

        </div>
      </Fragment>
    )
  }
};

export default Dashboard;
