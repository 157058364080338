import React, { Fragment, Component } from "react";
import DataGrid from "../../components/DataGrid/DataGrid";

// import actions
import { addressLoad } from "../../actions/reportsAction";

class Bank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      advisor: "ARN-110384",
      rowData: [],
      columnDefs: [
        { headerName: "Client", field: "client_name" },
        { headerName: "client_id", field: "client_id", hide: true },
        { headerName: "address_id", field: "address_id", hide: true },
        { headerName: "Address", field: "address" },
        { headerName: "City", field: "city" },
        { headerName: "Pincode", field: "pin_code" },
        { headerName: "State", field: "state" },
        { headerName: "Country", field: "country" },
        { headerName: "Email", field: "email" },
        { headerName: "Mobile", field: "mobile_number" }
      ]
    };
    // Bind the this context to the handler function
  }

  componentDidMount = () => {
    this.handleAddressLoad();
  };

  onRowSelected(event) {}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  handleAddressLoad = async () => {
    const err = "";
    if (!err) {
      const data = this.state.advisor;
      try {
        const clientDataRes = await addressLoad(data);
        const res = clientDataRes.data;
        if (res.status === "success" && res.data !== undefined) {
          this.setState({ rowData: res.data, fetching: true });
        }
      } catch (err) {
        console.error("handleAddressLoad, Server err: ", err.response);
      }
    }
  };

  render() {
    const { fetching, rowData } = this.state;
    return (
      <Fragment>
        <div id="processing">
          {!fetching && <div className="text-center">loading...</div>}
          {fetching && rowData.length > 0 && (
            <DataGrid
              ref="idreviewgrid"
              RowDef={this.state.rowData}
              onRowSelected={this.onRowSelected.bind(this)}
              onGridReady={this.onGridReady.bind(this)}
              ColumnDef={this.state.columnDefs}
              paginationPageSize="9"
              rowmodeltype="pagination"
              pagination={true}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default Bank;
