
export const MUTUAL_FUNDS = [
  { value: 'AFAD-DR-L1', label: 'RELIANCE ARBITRAGE ADVANTAGE FUND - DIRECT PLAN DIVIDEND PLAN - REINVESTMENT' },
  { value: 'AFAG-GR', label: 'RELIANCE ARBITRAGE ADVANTAGE FUND DIRECT PLAN GROWTH PLAN GROWTH' },
  { value: 'AFAG-GR-L1', label: 'RELIANCE ARBITRAGE ADVANTAGE FUND - DIRECT GROWTH PLAN GROWTH OPTION - GROWTH' },
  { value: 'AFMP-DP', label: 'RELIANCE ARBITRAGE ADVANTAGE FUND - MONTHLY DIVIDEND PLAN - DIVIDEND PAYOUT OPTION' },
  { value: 'AFMP-DP-L1', label: 'RELIANCE ARBITRAGE ADVANTAGE FUND - MONTHLY DIVIDEND PLAN - DIVIDEND REINVESTMENT OPTION' },
  { value: 'AFMP-DR', label: 'RELIANCE BANKING FUND DIRECT PLAN BONUS PLAN BONUS' },
  { value: 'AFMP-DR-L1', label: 'RELIANCE BANKING FUND DIRECT PLAN DIVIDEND PLAN DIVIDEND PAYOUT' },
  { value: 'BFAB-GR', label: 'RELIANCE BANKING FUND - DIRECT PLAN DIVIDEND PLAN - PAYOUT' },
  { value: 'BFAD-DP', label: 'RELIANCE BANKING FUND DIRECT PLAN DIVIDEND PLAN DIVIDEND REINVESTMENT' },
  { value: 'BFAD-DP-L1', label: 'RELIANCE BANKING FUND - DIRECT PLAN DIVIDEND PLAN - REINVESTMENT' },
  { value: 'BFAD-DR', label: 'RELIANCE BANKING FUND DIRECT PLAN GROWTH PLAN GROWTH' }
];

export const ADDITIONAL_FUNDS = [
  {
    scheme: 'ABC',
    folioNo: '123',
    units: '1000',
    amount: '10000'
  },
  {
    scheme: 'DEF',
    folioNo: '324',
    units: '1500',
    amount: '2000'
  }
];

export const REDEMPTION_DATA = [
  {
    schemeName: 'ABC',
    folioNo: '123',
    unit: '1000',
    currentValue: '10000',
  },
  {
    schemeName: 'DEF',
    folioNo: '123',
    unit: '1000',
    currentValue: '10000',
  }
];

export const SWITCH_DATA = [
  {
    schemeName: 'ABC',
    folioNo: '123',
    unit: '1000',
    currentValue: '10000',
  },
  {
    schemeName: 'DEF',
    folioNo: '123',
    unit: '1000',
    currentValue: '10000',
  }
];


