import React, { Component, Fragment } from "react";
import Toastr from 'toastr';
import Header from '../../components/Header/Header';
import config from '../../config';
import { storeAuthUser } from '../../utils/sessionManager';

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_login: '',
      user_pass: '',
      server_error: '',
    };
  }

  handleLogin = () => {
    if (this.state.user_login === '' || this.state.user_pass === '') {
      Toastr.error('Missing credentials', 'Invalid Input ...');
    } else {
      const userData = { user_login: this.state.user_login };
      Toastr.success('Welcome on Alchemy', 'Welcome !');
      storeAuthUser('user', userData);
      this.props.history.push('/dashboard');
    }
  }


  render() {
    const { user_login, user_pass } = this.state;
    const h = window.innerHeight;
    return (
      <Fragment>
        <Header />
        <div id="login_container" className="container-fluid" style={{ marginTop: (h / 2 - (150 + 50)) }}>
          <div className="row mb-xs-5 no-gutters">
            <div className="col-md-5 offset-md-2">
              <img
                className="img-fluid d-block mx-auto"
                src={config.s3_url + '/application.jpg'}
                height="300px"
                alt="Alchemy"
              />
            </div>
            <div className="col-md-3 bg-light px-4 pt-2">
              <div className="text-center mb-2">
                <img
                  src={config.s3_url + '/favicon.png'}
                  height="50px"
                  alt="logo"
                />
              </div>

              <label className="text-bold">Email / UserName </label>
              <input
                type="text"
                name="user_login"
                className="form-control md"
                autoComplete="off"
                placeholder="Email / User Name"
                value={user_login}
                onChange={(e)=> this.setState({user_login: e.target.value})}
              />

              <label className='text-bold mt-3'>Password</label>
              <input
                type="password"
                name="user_pass"
                className="form-control md"
                autoComplete="off"
                placeholder="Password"
                value={user_pass}
                onChange={(e)=> this.setState({user_pass: e.target.value})}
              />

              <input
                type="button"
                className="btn btn-sm btn-primary btn-block mt-4 mb-5"
                value="Login"
                onClick={this.handleLogin}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12"> &nbsp; </div>
          </div>
        </div>
      </Fragment>
    )
  }
};

export default LoginModal;
