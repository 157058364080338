import React, { Fragment, Component } from "react";
import "./DataGrid.scss";

import { AgGridReact } from "ag-grid-react";

class DataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: { componentParent: this },
      rowSelection: "single"
    };
  }

  render() {
    return (
      <Fragment>
        <div
          style={{ height: "auto !important" }}
          className="ag-theme-material"
        >
          <AgGridReact
            enableSorting="true"
            enableFilter={true}
            paginationPageSize={this.props.paginationPageSize}
            domLayout="autoHeight"
            rowmodeltype={this.props.rowmodeltype}
            pagination={this.props.pagination}
            columnDefs={this.props.ColumnDef}
            rowData={this.props.RowDef}
            onGridReady={this.props.onGridReady}
            enableColResize={true}
            context={this.state.context}
            suppressRowClickSelection={true}
            defaultColDef={this.props.defaultColDef}
            overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>'
            autoGroupColumnDef={this.props.autoGroupColumnDef}
            rowSelection={this.state.rowSelection}
            groupSelectsChildren={true}
            onRowSelected={this.props.onRowSelected}
            onSelectionChanged={this.props.onSelectionChanged}
          />
        </div>
      </Fragment>
    );
  }
}

export default DataGrid;
