import React, { Component, Fragment } from "react";
import Button from '../../components/Button/Button';
import SwitchSchemeModal from './SwitchSchemeModal';

// import actions
import { getConsolidated } from '../../actions/transactionActions';

const client = {
  "advisor_id": "ARN-110384",
  "client_id": "1081227",
  "sub_advisor_id": ""
};
class SwitchScheme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      funds: [],
      fetching: true,
      server_error: '',
      showSwitchModal: false,
      selectedScheme: {},
    };
  }

  getInitialData = async () => {
    try {
      const firstResponse = await getConsolidated(client);
      const consolidate = firstResponse.data;
      consolidate.data.forEach(fund => {
        fund.isAmount = true;
      });
      this.setState({
        funds: consolidate.data,
        fetching: false,
      });

    } catch (err) {
      console.error('getInitialData, Server err: ', err.response);
      const data = err.response.data;
      this.setState({ server_error: data.error });
    }
  }

  componentDidMount() {
    this.getInitialData();
  }

  handleAmount = (index) => {
    const funds = this.state.funds;
    const fund = funds[index];
    fund.isAmount = !fund.isAmount;
    this.setState({ funds });
  }

  handleSwithModal = (index) => {
    const selectedScheme = this.state.funds[index];
    this.setState({
      selectedScheme,
      showSwitchModal: true
    });
  }

  render() {
    const { funds, fetching } = this.state;
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="col al_card" id="al_redemption">

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-hover al_table">
                    <thead>
                      <tr>
                        <th scope="col">Scheme</th>
                        <th scope="col">Folio No</th>
                        <th scope="col">Units</th>
                        <th scope="col">Current Value</th>
                        <th scope="col"><strong>Switch</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      {fetching && (
                        <tr>
                          <td colSpan="20" className="text-center">loading...</td>
                        </tr>
                      )}
                      {!fetching && funds.length > 0 && (
                        funds.map((fund, index) => {
                          return (
                            <tr key={index}>
                              <td>{fund.scheme_name}</td>
                              <td>{fund.scheme_folio_no}</td>
                              <td>{fund.unit_balance}</td>
                              <td>{fund.current_value}</td>
                              <td>
                                <div className="row no-gutters">
                                  <div className="form-check mr-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={'redemAmount' + index}
                                      id={'amount' + index}
                                      checked={fund.isAmount}
                                      onChange={() => this.handleAmount(index)}
                                    />
                                    <label className="form-check-label" htmlFor={'amount' + index} style={{ marginTop: '3px' }}>
                                      By Amount
                                    </label>
                                  </div>
                                  <div className="form-check mr-4">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={'redemAmount' + index}
                                      id={'units' + index}
                                      checked={!fund.isAmount}
                                      onChange={() => this.handleAmount(index)}
                                    />
                                    <label className="form-check-label" htmlFor={'units' + index} style={{ marginTop: '3px' }}>
                                      By Units
                                    </label>
                                  </div>
                                  <div className="table_input">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="amount"
                                      placeholder={fund.isAmount ? 'Amount' : 'Units'}
                                    />
                                  </div>
                                  <div className="ml-4">
                                    <Button
                                      type="button"
                                      text="Switch"
                                      size="sm"
                                      onClick={() => this.handleSwithModal(index)}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <SwitchSchemeModal
                  show={this.state.showSwitchModal}
                  onHide={() => this.setState({ showSwitchModal: false })}
                  scheme={this.state.selectedScheme}
                />
              </div>
            </div>

            <div className="row justify-content-center mt-3">
              <div className="mr-4">
                <Button type="button" text="Transact Now" />
              </div>
              <Button type="button" text="Add to Cart" />
            </div>

          </div>
        </div>
      </Fragment>
    )
  }
};

export default SwitchScheme;
