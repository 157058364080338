import React, { Fragment, Component } from "react";
import DatePicker from "react-datepicker";
import Toastr from "toastr";
import Autocomplete from "../../components/AutoComplete/AutoComplete";
// import actions
import { panSearch, nameSearch } from "../../actions/kycSearchAction";

class KYCSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountTypeData: "",
      accountCreationType: "",
      display1joint: false,
      display2joint: false,
      jointClass: "nojoint",
      joint: 0,
      dob: "",
      dob1: "",
      dob2: "",
      fields: {},
      errors: {
        pan: false,
        name: false,
        pan1: false,
        name1: false,
        pan2: false,
        name2: false
      },
      clientData: [],
      value: "",
      name: "",
      user1Data: [],
      user2Data: [],
      user3Data: [],
      user1Name: {
        prefix: "",
        data: []
      },
      user2Name: {
        prefix: "",
        data: []
      },
      user3Name: {
        prefix: "",
        data: []
      },
      nameSearchRequest: {
        advisor_id: "ARN-110384",
        sub_advisor_id: "",
        client_name: ""
      }
    };
    this.getUser1Data = this.getUser1Data.bind(this);
    this.getUser2Data = this.getUser2Data.bind(this);
    this.getUser3Data = this.getUser3Data.bind(this);

    this.match1stAppData = this.match1stAppData.bind(this);
    this.match2ndAppData = this.match2ndAppData.bind(this);
    this.match3rdAppData = this.match3rdAppData.bind(this);

    this.handlePANKeyUp = this.keyUpHandler.bind(this, "PAN");
    this.handleNameKeyUp = this.keyUpHandler.bind(this, "name");
    this.handlePAN1KeyUp = this.keyUpHandler.bind(this, "PAN1");
    this.handleName1KeyUp = this.keyUpHandler.bind(this, "name1");
    this.handlePAN2KeyUp = this.keyUpHandler.bind(this, "PAN1");
    this.handleName2KeyUp = this.keyUpHandler.bind(this, "name1");
  }

  componentWillMount = () => {
    if (this.props.accountTypeData != null) {
      this.setState(
        { accountTypeData: this.props.accountTypeData },
        function() {
          this.setState(
            { joint: this.state.accountTypeData.joint },
            function() {}
          );
          this.setState(
            {
              accountCreationType: this.state.accountTypeData
                .accountCreationType
            },
            function() {}
          );

          switch (this.state.accountTypeData.joint) {
            case "1":
              this.setState({ display1joint: true }, function() {});
              this.setState({ jointClass: "onejoint" });
              break;
            case "2":
              this.setState({ display2joint: true }, function() {});
              this.setState({ jointClass: "twojoint" });
              break;
            default:
              this.setState({ display1joint: false });
              this.setState({ display2joint: false });

              break;
          }
        }
      );
    }
  };

  keyUpHandler(refName, e) {
    const x = e.target.value;

    if (refName === "PAN") {
      if (!x.match(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/)) {
        this.setState({ errors: { ...this.state.errors, pan: true } });
        Toastr.clear();
        Toastr.error("Valid PAN No. is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, pan: false } });
      }
    } else if (refName === "name") {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, name: true } });
        Toastr.clear();
        Toastr.error("Valid Name is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, name: false } });
      }
    }
    if (refName === "PAN1") {
      if (!x.match(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/)) {
        this.setState({ errors: { ...this.state.errors, pan1: true } });
        Toastr.clear();
        Toastr.error("Valid 2nd Applicant PAN No. is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, pan1: false } });
      }
    } else if (refName === "name1") {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, name1: true } });
        Toastr.clear();
        Toastr.error("Valid 2nd Applicant Name is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, name1: false } });
      }
    }
    if (refName === "PAN2") {
      if (!x.match(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/)) {
        this.setState({ errors: { ...this.state.errors, pan2: true } });
        Toastr.clear();
        Toastr.error("Valid 3rd Applicant PAN No. is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, pan2: false } });
      }
    } else if (refName === "name2") {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, name2: true } });
        Toastr.clear();
        Toastr.error("Valid 3rd Applicant Name is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, name2: false } });
      }
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (typeof fields["pan"] !== "undefined") {
      if (!fields["pan"].match(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/)) {
        formIsValid = false;
        errors["pan"] = "Valid PAN No. is required.";
        Toastr.error("Valid PAN No. is required.");
      }
    }
    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "Valid Name is required.";
        Toastr.error("Valid Name is required.");
      }
    }

    let x = null;
    if (this.state.joint > 0) {
      x = this.state.joint;
    }

    for (let i = 0; i <= x; i++) {
      let n = i > 0 ? i : "";
      let dob =
        i === 0 ? this.state.dob : i === 1 ? this.state.dob1 : this.state.dob2;
      if (this.state.accountCreationType === "RTA") {
        if (!fields["name" + n] && !fields["pan" + n]) {
          formIsValid = false;
          Toastr.error("PAN No. is required.");
        } else if (!fields["name" + n] && dob !== "") {
          formIsValid = false;
          Toastr.error("Name is required.");
        }
      } else {
        if (
          typeof fields["name" + n] !== "undefined" &&
          typeof fields["pan" + n] !== "undefined"
        ) {
          formIsValid = false;
          Toastr.error("Enter either only PAN No. or Name & Date.");
        } else if (!fields["name" + n] && !fields["pan" + n] && dob === "") {
          formIsValid = false;
          Toastr.error("PAN No. is required.");
        } else if (typeof fields["name" + n] !== "undefined" && dob === "") {
          formIsValid = false;
          Toastr.error("Date field is required." + i);
        } else if (!fields["name" + n] && dob !== "") {
          formIsValid = false;
          Toastr.error("Name is required.");
        }
      }
    }

    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  autocompleteSelect(value, name, pan, panName) {
    let fields = this.state.fields;
    fields[name] = value;
    fields[panName] = pan;
    this.setState({ fields }, function() {});
  }

  handleDOBChange(field, date) {
    this.setState({
      dob: date
    });
    let fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields });
  }

  handleDOB1Change(field, date) {
    this.setState({
      dob1: date
    });
    let fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields });
  }

  handleDOB2Change(field, date) {
    this.setState({
      dob2: date
    });
    let fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields });
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.handleValidation()) {
      event.target.className += " needs-validation";
      if (this.state.accountCreationType === "RTA") {
        this.handlePANSearch(event);
      } else if (this.state.accountCreationType === "New") {
        const kycVerification = {
          kyc: true,
          kycData: {
            applicantName: "Name",
            fsName: "Father Name",
            mobile: "8105705720",
            email: "gomathig@gmail.com",
            pan: this.state.fields["pan"],
            aadhar: "",
            dob: "",
            status: "Resident Individual",
            cAddress: {
              addressLine1: "",
              addressLine2: "",
              addressLine3: "",
              addressLine4: "",
              city: "",
              state: "",
              pincode: 1231,
              country: ""
            },
            pAddress: {
              addressLine1: "",
              addressLine2: "",
              addressLine3: "",
              addressLine4: "",
              city: "",
              state: "",
              pincode: 1231,
              country: ""
            }
          },
          kyc1: true,
          kyc2: true
        };
        this.props.handleAccountSubmit(event, kycVerification);
      }
    } else {
      event.target.className += " was-validated";
    }
  };

  getUser1Data = name => {
    if (
      this.state.accountCreationType === "RTA" &&
      this.state.fields[name] !== undefined &&
      this.state.fields[name].length > 2
    ) {
      if (
        this.state.user1Name.prefix === this.state.fields[name].substring(0, 3)
      ) {
        return this.state.user1Name.data;
      } else {
        this.handleNAMESearch(this.state.fields[name], 1);
      }
    }
    return this.state.user1Data;
  };

  getUser2Data = name => {
    if (
      this.state.accountCreationType === "RTA" &&
      this.state.fields[name] !== undefined &&
      this.state.fields[name].length > 2
    ) {
      if (
        this.state.user2Name.prefix === this.state.fields[name].substring(0, 3)
      ) {
        return this.state.user2Name.data;
      } else {
        this.handleNAMESearch(this.state.fields[name], 2);
      }
    }
    return this.state.user2Data;
  };

  getUser3Data = name => {
    if (
      this.state.accountCreationType === "RTA" &&
      this.state.fields[name] !== undefined &&
      this.state.fields[name].length > 2
    ) {
      if (
        this.state.user3Name.prefix === this.state.fields[name].substring(0, 3)
      ) {
        return this.state.user3Name.data;
      } else {
        this.handleNAMESearch(this.state.fields[name], 3);
      }
    }
    return this.state.user3Data;
  };

  match1stAppData(state, value) {
    return (
      this.state.client_name.toLowerCase().indexOf(value.toLowerCase()) !==
        -1 ||
      this.state.pan_number.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  match2ndAppData(state, value) {
    return (
      this.state.client_name.toLowerCase().indexOf(value.toLowerCase()) !==
        -1 ||
      this.state.pan_number.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  match3rdAppData(state, value) {
    return (
      this.state.client_name.toLowerCase().indexOf(value.toLowerCase()) !==
        -1 ||
      this.state.pan_number.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handlePANSearch = async event => {
    const err = "";
    let ClientData = this.state.clientData;
    if (!err) {
      for (let i = 0; i <= this.state.joint; i++) {
        let item = this.state.clientData[i];
        let data =
          i > 0 ? this.state.fields["pan" + i] : this.state.fields["pan"];

        try {
          const clientDataRes = await panSearch(data);
          const res = clientDataRes.data;
          item = res.data;
          ClientData[i] = item[i];
          console.log(
            "pan" + i + JSON.stringify(data) + JSON.stringify(item[i])
          );
          Toastr.success("PAN search is successfully.", "Success");
        } catch (err) {
          console.error("handlePANSearch, Server err: ", err.response);
          const data = err.response.data;
          this.setState({ server_error: data.error });
        }
      }
      this.setState({ clientData: ClientData }, function() {
        console.log("data in client " + JSON.stringify(this.state.clientData));
        this.props.handleAccountSubmit(event, this.state.clientData);
      });
    }
  };

  handleNAMESearch = async (name, id) => {
    const err = "";
    if (!err) {
      try {
        const res = await nameSearch(name);
        const data = res.data;
        console.log(name + " " + id + JSON.stringify(data));
        let username;

        if (data.status === "success" && data.data !== undefined) {
          if (id === 1) {
            this.setState({ user1Data: data.data }, function() {
              console.log("data in userData " + this.state.user1Data);
            });
            username = this.state.user1Name;
            username.prefix = name.substring(0, 3);
            username.data = data.data;
            this.setState({ user1Name: username }, function() {
              console.log("data in userData " + this.state.user1Name);
            });
          } else if (id === 2) {
            this.setState({ user2Data: data.data }, function() {
              console.log("data in userData " + this.state.user2Data);
            });
            username = this.state.user2Name;
            username.prefix = name.substring(0, 3);
            username.data = data.data;
            this.setState({ user2Name: username }, function() {
              console.log("data in userData " + this.state.user2Name);
            });
          } else if (id === 3) {
            this.setState({ user3Data: data.data }, function() {
              console.log("data in userData " + this.state.user3Data);
            });
            username = this.state.user3Name;
            username.prefix = name.substring(0, 3);
            username.data = data.data;
            this.setState({ user3Name: username }, function() {
              console.log("data in userData " + this.state.user3Name);
            });
          }
        } else {
          if (id === 1) {
            username = this.state.user1Name;
            username.prefix = name.substring(0, 3);
            username.data = [];
            this.setState({ user1Name: username }, function() {
              console.log("data in userData " + this.state.user1Name);
            });
            this.setState({ user1Data: [] }, function() {
              console.log("data in userData " + this.state.user1Data);
            });
          } else if (id === 2) {
            username = this.state.user2Name;
            username.prefix = name.substring(0, 3);
            username.data = [];
            this.setState({ user2Name: username }, function() {
              console.log("data in userData " + this.state.user2Name);
            });
            this.setState({ user2Data: [] }, function() {
              console.log("data in userData " + this.state.user2Data);
            });
          } else if (id === 3) {
            username = this.state.user3Name;
            username.prefix = name.substring(0, 3);
            username.data = [];
            this.setState({ user3Name: username }, function() {
              console.log("data in userData " + this.state.user3Name);
            });
            this.setState({ user3Data: [] }, function() {
              console.log("data in userData " + this.state.user3Data);
            });
          }

          Toastr.error("User data not found");
        }
      } catch (error) {
        console.error("handleNAMESearch, Server err: ", JSON.stringify(error));
      }
    }
  };

  render() {
    let pan = (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group form-input">
            <input
              name="pan"
              id="pan"
              MaxLength="10"
              required
              className={this.state.errors.pan ? "error" : "success"}
              value={this.state.fields["pan"]}
              type="text"
              ref="pan"
              onBlur={this.handlePANKeyUp}
              onChange={this.handleChange.bind(this, "pan")}
            />
            <label for="pan" className="form-label">
              1st Applicant PAN No.
            </label>
          </div>
        </div>
      </div>
    );

    let applicant = (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group form-input">
            <Autocomplete
              value={this.state.fields["name"]}
              inputId="name"
              labelText="1st Applicant Name"
              items={this.getUser1Data("name")}
              getItemValue={item => item.client_name}
              // shouldItemRender={ this.matchUserData }
              onChangeClick={this.handleChange.bind(this, "name")}
              onSelect={(value, item) =>
                this.autocompleteSelect(value, "name", item.pan_number, "pan")
              }
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.pan_number}
                >
                  {item.client_name}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );

    let dob = (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group form-input">
            <label for="dob" className="test-valid-date">
              Date of Birth / Date of Incorporation
            </label>
            <DatePicker
              name="dob"
              id="dob"
              type="date"
              className="date"
              required
              showMonthDropdown
              showYearDropdown
              selected={this.state.dob}
              onChange={this.handleDOBChange.bind(this, "dob")}
              placeholderText="Date of Birth / Date of Incorporation"
            />
          </div>
        </div>
      </div>
    );

    if (this.state.display1joint) {
      pan = (
        <div className="row">
          <div className="col-md-6">
            <div className="form-group form-input">
              <input
                name="pan"
                id="pan"
                MaxLength="10"
                required
                className={this.state.errors.pan ? "error" : "success"}
                value={this.state.fields["pan"]}
                type="text"
                ref="pan"
                onBlur={this.handlePANKeyUp}
                onChange={this.handleChange.bind(this, "pan")}
              />
              <label for="pan" className="form-label">
                1st Applicant PAN No.
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group form-input">
              <input
                name="pan1"
                id="pan1"
                MaxLength="10"
                required
                className={this.state.errors.pan1 ? "error" : "success"}
                value={this.state.fields["pan1"]}
                type="text"
                ref="pan1"
                onBlur={this.handlePAN1KeyUp}
                onChange={this.handleChange.bind(this, "pan1")}
              />
              <label for="pan1" className="form-label">
                2nd Applicant PAN No.
              </label>
            </div>
          </div>
        </div>
      );

      applicant = (
        <div className="row">
          <div className="col-md-6">
            <div className="form-group form-input">
              <Autocomplete
                value={this.state.fields["name"]}
                inputId="name"
                labelText="1st Applicant Name"
                items={this.getUser1Data("name")}
                getItemValue={item => item.client_name}
                shouldItemRender={this.match1stAppData}
                onChangeClick={this.handleChange.bind(this, "name")}
                onSelect={(value, item) =>
                  this.autocompleteSelect(value, "name", item.pan_number, "pan")
                }
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${
                      isHighlighted ? "item-highlighted" : ""
                    }`}
                    key={item.pan_number}
                  >
                    {item.client_name}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group form-input">
              <Autocomplete
                value={this.state.fields["name1"]}
                inputId="name1"
                labelText="2nd Applicant Name"
                items={this.getUser2Data("name1")}
                getItemValue={item => item.client_name}
                shouldItemRender={this.match2ndAppData}
                onChangeClick={this.handleChange.bind(this, "name1")}
                onSelect={(value, item) =>
                  this.autocompleteSelect(
                    value,
                    "name1",
                    item.pan_number,
                    "pan1"
                  )
                }
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${
                      isHighlighted ? "item-highlighted" : ""
                    }`}
                    key={item.pan_number}
                  >
                    {item.client_name}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      );

      dob = (
        <div className="row">
          <div className="col-md-6">
            <div className="form-group form-input">
              <label for="dob" className="test-valid-date">
                Date of Birth / Date of Incorporation
              </label>
              <DatePicker
                name="dob"
                id="dob"
                type="date"
                className="date"
                required
                showMonthDropdown
                showYearDropdown
                selected={this.state.dob}
                onChange={this.handleDOBChange.bind(this, "dob")}
                placeholderText="Date of Birth / Date of Incorporation"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group form-input">
              <label for="dob1" className="test-valid-date">
                Date of Birth / Date of Incorporation
              </label>
              <DatePicker
                name="dob1"
                id="dob1"
                type="date"
                className="date"
                required
                showMonthDropdown
                showYearDropdown
                selected={this.state.dob1}
                onChange={this.handleDOB1Change.bind(this, "dob1")}
                placeholderText="Date of Birth / Date of Incorporation"
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.display2joint) {
      pan = (
        <div className="row">
          <div className="col-md-4">
            <div className="form-group form-input">
              <input
                name="pan"
                id="pan"
                MaxLength="10"
                required
                className={this.state.errors.pan ? "error" : "success"}
                value={this.state.fields["pan"]}
                type="text"
                ref="pan"
                onBlur={this.handlePANKeyUp}
                onChange={this.handleChange.bind(this, "pan")}
              />
              <label for="pan" className="form-label">
                1st Applicant PAN No.
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group form-input">
              <input
                name="pan1"
                id="pan1"
                MaxLength="10"
                required
                className={this.state.errors.pan1 ? "error" : "success"}
                value={this.state.fields["pan1"]}
                type="text"
                ref="pan1"
                onBlur={this.handlePAN1KeyUp}
                onChange={this.handleChange.bind(this, "pan1")}
              />
              <label for="pan1" className="form-label">
                2nd Applicant PAN No.
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group form-input">
              <input
                name="pan2"
                id="pan2"
                MaxLength="10"
                required
                className={this.state.errors.pan2 ? "error" : "success"}
                value={this.state.fields["pan2"]}
                type="text"
                ref="pan2"
                onBlur={this.handlePAN2KeyUp}
                onChange={this.handleChange.bind(this, "pan2")}
              />
              <label for="pan2" className="form-label">
                3rd Applicant PAN No.
              </label>
            </div>
          </div>
        </div>
      );
      applicant = (
        <div className="row">
          <div className="col-md-4">
            <div className="form-group form-input">
              <Autocomplete
                value={this.state.fields["name"]}
                inputId="name1"
                labelText="1st Applicant Name"
                items={this.getUser1Data("name")}
                getItemValue={item => item.client_name}
                // shouldItemRender={ this.matchUserData }
                onChangeClick={this.handleChange.bind(this, "name")}
                onSelect={(value, item) =>
                  this.autocompleteSelect(value, "name", item.pan_number, "pan")
                }
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${
                      isHighlighted ? "item-highlighted" : ""
                    }`}
                    key={item.pan_number}
                  >
                    {item.client_name}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group form-input">
              <Autocomplete
                value={this.state.fields["name1"]}
                inputId="name1"
                labelText="2nd Applicant Name"
                items={this.getUser2Data("name1")}
                getItemValue={item => item.client_name}
                // shouldItemRender={ this.matchUserData }
                onChangeClick={this.handleChange.bind(this, "name1")}
                onSelect={(value, item) =>
                  this.autocompleteSelect(
                    value,
                    "name1",
                    item.pan_number,
                    "pan1"
                  )
                }
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${
                      isHighlighted ? "item-highlighted" : ""
                    }`}
                    key={item.pan_number}
                  >
                    {item.client_name}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group form-input">
              <Autocomplete
                value={this.state.fields["name2"]}
                inputId="name2"
                labelText="3rd Applicant Name"
                items={this.getUser3Data("name2")}
                getItemValue={item => item.client_name}
                // shouldItemRender={ this.matchUserData }
                onChangeClick={this.handleChange.bind(this, "name2")}
                onSelect={(value, item) =>
                  this.autocompleteSelect(
                    value,
                    "name2",
                    item.pan_number,
                    "pan2"
                  )
                }
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${
                      isHighlighted ? "item-highlighted" : ""
                    }`}
                    key={item.pan_number}
                  >
                    {item.client_name}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      );

      dob = (
        <div className="row">
          <div className="col-md-4">
            <div className="form-group form-input">
              <label for="dob" className="test-valid-date">
                Date of Birth / Date of Incorporation
              </label>
              <DatePicker
                name="dob"
                id="dob"
                type="date"
                className="date"
                required
                showMonthDropdown
                showYearDropdown
                selected={this.state.dob}
                onChange={this.handleDOBChange.bind(this, "dob")}
                placeholderText="Date of Birth / Date of Incorporation"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group form-input">
              <label for="dob1" className="test-valid-date">
                Date of Birth / Date of Incorporation
              </label>
              <DatePicker
                name="dob1"
                id="dob1"
                type="date"
                className="date"
                required
                showMonthDropdown
                showYearDropdown
                selected={this.state.dob1}
                onChange={this.handleDOB1Change.bind(this, "dob1")}
                placeholderText="Date of Birth / Date of Incorporation"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group form-input">
              <label for="dob2" className="test-valid-date">
                Date of Birth / Date of Incorporation
              </label>
              <DatePicker
                name="dob2"
                id="dob2"
                type="date"
                className="date"
                required
                showMonthDropdown
                showYearDropdown
                selected={this.state.dob2}
                onChange={this.handleDOB2Change.bind(this, "dob2")}
                placeholderText="Date of Birth / Date of Incorporation"
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <Fragment>
        <div className="main">
          <div className="container">
            <div
              className="container-fluid"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div
                className={
                  this.state.jointClass +
                  " bg-lighter px-4 pt-2 padding-top-bottom"
                }
              >
                <form
                  className="needs-validation"
                  onSubmit={this.handleSubmit.bind(this)}
                  noValidate
                >
                  <h2>
                    {this.state.accountCreationType === "RTA"
                      ? "Search"
                      : "KYC Search"}
                  </h2>
                  {pan}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group form-input">
                        <label for="or" className="form-label">
                          OR
                        </label>
                      </div>
                    </div>
                  </div>
                  <br />

                  {applicant}
                  {this.state.accountCreationType === "RTA" ? "" : dob}
                  <div className="form-submit">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-warning right"
                      id="submit"
                      name="submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default KYCSearch;
