import React, { Fragment, Component } from "react";
import Header from "../../components/Header/Header";
import Toastr from "toastr";

class AOForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nominations: [
        {
          nname: "",
          nrelationship: "",
          ngname: "",
          nominationPerc: "",
          address1: "",
          address2: "",
          address3: "",
          address4: "",
          country: "",
          nstate: "",
          npincode: "",
          ncity: "",
          nnameErr: false,
          nrelationshipErr: false,
          ngnameErr: false,
          nominationPercErr: false,
          address1Err: false,
          address2Err: false,
          address3ErrErr: false,
          address4Err: false,
          countryErr: false,
          nstateErr: false,
          npincodeErr: false,
          ncityErr: false
        }
      ]
    };
  }

  componentWillMount = () => {
    let fields = this.state.nominations;
    let field = this.state.nominations[0];
    field["nname"] = this.props.data["nominee_name"];
    field["nrelationship"] = this.props.data["nominee_relation"];
    field["nominationPerc"] = this.props.data["nominee_percent"];
    field["address1"] = this.props.data["nominee_address"];
    field["ncity"] = this.props.data["nominee_city"];
    field["nstate"] = this.props.data["nominee_state"];
    field["npincode"] = this.props.data["nominee_pincode"];
    fields[0] = field;
    this.setState({ nominations: fields });
  };

  handleChange = idx => e => {
    const name = e.target.name;
    const value = e.target.value;

    let items = this.state.nominations;
    const item = this.state.nominations[idx];
    item[name] = value;
    items[idx] = item;

    this.setState({ nominations: items },
      function() {
        console.log(JSON.stringify(this.state.nominations))
      }
      );
   
  };

  handleAddRow = () => {
    const item = {
      nname: "",
      nrelationship: "",
      ngname: "",
      nominationPerc: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      country: "",
      nstate: "",
      npincode: "",
      ncity: "",
      nnameErr: false,
      nrelationshipErr: false,
      ngnameErr: false,
      nominationPercErr: false,
      address1Err: false,
      address2Err: false,
      address3ErrErr: false,
      address4Err: false,
      countryErr: false,
      nstateErr: false,
      npincodeErr: false,
      ncityErr: false
    };
    this.setState(
      {
        nominations: [...this.state.nominations, item]
      },
      () => console.log(this.state.nominations)
    );
  };

  handleRemoveSpecificRow = idx => () => {
    if (idx !== 0) {
      const nominations = [...this.state.nominations];
      nominations.splice(idx, 1);
      this.setState({ nominations });
      console.log({ nominations });
    }
  };

  keyUpHandler = idx => e => {
    const refName = e.target.name;
    const x = e.target.value;
    let items = this.state.nominations;
    const item = this.state.nominations[idx];

    if (refName === "nominationPerc") {
      if (!x.match(/^[0-9]{2}$/)) {
        item[refName + "Err"] = true;
        items[idx] = item;

        this.setState({ nominations: items });

        Toastr.clear();
        Toastr.error("Valid Nomination Percentage is required.");
      } else {
        item[refName + "Err"] = false;
        items[idx] = item;

        this.setState({ nominations: items });
        Toastr.clear();
      }
    }
    if (
      refName === "nname" ||
      refName === "nrelationship" ||
      refName === "ngname" ||
      refName === "nstate" ||
      refName === "ncity" ||
      refName === "ncountry"
    ) {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        item[refName + "Err"] = true;
        items[idx] = item;

        this.setState({ nominations: items });

        Toastr.clear();
        Toastr.error("Valid " + [refName] + " is required.");
      } else {
        item[refName + "Err"] = false;
        items[idx] = item;

        this.setState({ nominations: items });
        Toastr.clear();
      }
    }
    if (
      refName === "address1" ||
      refName === "address2" ||
      refName === "address3" ||
      refName === "address4"
    ) {
      if (!x.match(/^[a-zA-Z0-9 ]*$/)) {
        item[refName + "Err"] = true;
        items[idx] = item;

        this.setState({ nominations: items });

        Toastr.clear();
        Toastr.error("Valid " + [refName] + " is required.");
      } else {
        item[refName + "Err"] = false;
        items[idx] = item;

        this.setState({ nominations: items });
        Toastr.clear();
      }
    }
    if (refName === "npincode") {
      if (!x.match(/^\d{6}$/)) {
        item[refName + "Err"] = true;
        items[idx] = item;

        this.setState({ nominations: items });

        Toastr.clear();
        Toastr.error("Valid pincode is required.");
      } else {
        item[refName + "Err"] = false;
        items[idx] = item;

        this.setState({ nominations: items });
        Toastr.clear();
      }
    }
  };

  handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let l = this.state.nominations.length;
    for (let i = 0; i < l; i++) {
      let fields = this.state.nominations[i];

      if (typeof fields["nname"] !== "undefined") {
        if (!fields["nname"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["nname"] = "Valid name is required.";
          Toastr.error("Valid name is required.");
        }
      }
      if (typeof fields["nrelationship"] !== "undefined") {
        if (!fields["nrelationship"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["nrelationship"] = "Valid relationship is required.";
          Toastr.error("Valid relationship is required.");
        }
      }
      if (typeof fields["ngname"] !== "undefined") {
        if (!fields["ngname"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["ngname"] = "Valid Guardian name is required.";
          Toastr.error("Valid Guardian name is required.");
        }
      }
      if (typeof fields["nominationPerc"] !== "undefined") {
        if (!fields["nominationPerc"].match(/^\d{2}$/)) {
          formIsValid = false;
          errors["nominationPerc"] = "Valid Percentage is required.";
          Toastr.error("Valid Percentage is required.");
        }
      }

      if (typeof fields["address1"] !== "undefined") {
        if (!fields["address1"].match(/^[a-zA-Z0-9 ]*$/)) {
          formIsValid = false;
          errors["address1"] = "Valid Address1 is required.";
          Toastr.error("Valid Address1 is required.");
        }
      }
      if (typeof fields["address2"] !== "undefined") {
        if (!fields["address2"].match(/^[a-zA-Z0-9 ]*$/)) {
          formIsValid = false;
          errors["address2"] = "Valid Address2 is required.";
          Toastr.error("Valid Address2 is required.");
        }
      }
      if (typeof fields["address3"] !== "undefined") {
        if (!fields["address3"].match(/^[a-zA-Z0-9 ]*$/)) {
          formIsValid = false;
          errors["address3"] = "Valid address3 is required.";
          Toastr.error("Valid address3 is required.");
        }
      }
      if (typeof fields["address4"] !== "undefined") {
        if (!fields["address4"].match(/^[a-zA-Z0-9 ]*$/)) {
          formIsValid = false;
          errors["address4"] = "Valid address4 is required.";
          Toastr.error("Valid address4 is required.");
        }
      }
      if (typeof fields["npincode"] !== "undefined") {
        if (!fields["npincode"].match(/^\d{6}$/)) {
          formIsValid = false;
          errors["npincode"] = "Valid pincode is required.";
          Toastr.error("Valid pincode is required.");
        }
      }
      if (typeof fields["ncity"] !== "undefined") {
        if (!fields["ncity"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["ncity"] = "Valid city is required.";
          Toastr.error("Valid city is required.");
        }
      }
      if (typeof fields["nstate"] !== "undefined") {
        if (!fields["nstate"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["nstate"] = "Valid state is required.";
          Toastr.error("Valid state is required.");
        }
      }
      if (typeof fields["country"] !== "undefined") {
        if (!fields["country"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["country"] = "Valid country is required.";
          Toastr.error("Valid country is required.");
        }
      }
    }
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      event.target.className += " needs-validation";
      Toastr.success("Account Creation", "Account Creation was successful");
    } else {
      event.target.className += " was-validated";
      Toastr.error("Form has errors.", "Form has errors.");
    }
  }
  render() {
    let { nominations } = this.state;
    return (
      <Fragment>
        <Header userRole="user" />
        <div className="col-md-12 col-offset-6 bg-lighter px-4 pt-2 padding-top-bottom">
          <form
            className="col-md-12 needs-validation"
            onSubmit={this.handleSubmit}
          >
            {nominations.map((item, idx) => (
              <div key={idx}>
                <div className="row">
                  <div className="col-md-11">
                    <label htmlFor={"nomination-$" + idx}>{`Nomination #${idx +
                      1}`}</label>
                  </div>
                  <div className="col-md-1 form-submit">
                    <input
                      type="submit"
                      value="Delete"
                      onClick={this.handleRemoveSpecificRow(idx)}
                      className={idx > 0 ? "btn btn-danger btn-md mx-1 px-5 button-width right" : "hidden"}
                      name="submit"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="nname"
                        id="nname"
                        required
                        value={nominations[idx].nname}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="nname"
                        className={
                          nominations[idx].nnameErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="nname" className="form-label">
                        Nominee Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="nrelationship"
                        id="nrelationship"
                        required
                        value={nominations[idx].nrelationship}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="nrelationship"
                        className={
                          nominations[idx].nrelationshipErr
                            ? "error"
                            : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="nrelationship" className="form-label">
                        Relationship
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="ngname"
                        id="ngname"
                        required
                        value={nominations[idx].ngname}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="ngname"
                        className={
                          nominations[idx].ngnameErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="ngname" className="form-label">
                        Guardian Name (If Nominee is Minor)
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="nominationPerc"
                        id="nominationPerc"
                        required
                        value={nominations[idx].nominationPerc}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="2"
                        type="text"
                        ref="nominationPerc"
                        className={
                          nominations[idx].nominationPercErr
                            ? "error"
                            : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="nominationPerc" className="form-label">
                        Nomination %
                      </label>
                    </div>
                  </div>
                </div>
                <h5>Nominee Address</h5>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="address1"
                        id="address1"
                        required
                        value={nominations[idx].address1}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="address1"
                        className={
                          nominations[idx].address1Err ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="address1" className="form-label">
                        Address Line 1
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="address2"
                        id="address2"
                        required
                        value={nominations[idx].address2}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="address2"
                        className={
                          nominations[idx].address2Err ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="address2" className="form-label">
                        Address Line 2
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="address3"
                        id="address3"
                        required
                        value={nominations[idx].address3}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="address3"
                        className={
                          nominations[idx].address3Err ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="address3" className="form-label">
                        Address Line 3
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="address4"
                        id="address4"
                        required
                        value={nominations[idx].address4}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="address4"
                        className={
                          nominations[idx].address4Err ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="address4" className="form-label">
                        Address Line 4
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="nstate"
                        id="nstate"
                        required
                        value={nominations[idx].nstate}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="nstate"
                        className={
                          nominations[idx].nstateErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="nstate" className="form-label">
                        State
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="ncity"
                        id="ncity"
                        required
                        value={nominations[idx].ncity}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="ncity"
                        className={
                          nominations[idx].ncityErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="ncity" className="form-label">
                        City
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="npincode"
                        id="npincode"
                        required
                        value={nominations[idx].npincode}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="npincode"
                        className={
                          nominations[idx].npincodeErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="npincode" className="form-label">
                        Pincode
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="country"
                        id="country"
                        required
                        value={nominations[idx].country}
                        data-id={idx}
                        onChange={this.handleChange(idx)}
                        MaxLength="25"
                        type="text"
                        ref="country"
                        className={
                          nominations[idx].countryErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                      />
                      <label for="country" className="form-label">
                        Country
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="form-submit">
              <input
                type="submit"
                value="Add"
                onClick={this.handleAddRow}
                // onClick={this.addBank}
                className="btn btn-md mx-1 btn-dark px-5 button-width"
                id="submit"
                name="submit"
              />
            </div>
            <div className="form-submit">
              <input
                type="submit"
                value="Submit"
                className="btn btn-md mx-1 btn-warning px-5 button-width right"
                id="submit"
                name="submit"
              />
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default AOForm;
