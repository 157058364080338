
import ls from 'local-storage';

export const storeAuthUser = (key, val) => {
  const expires = new Date();
  expires.setMinutes(expires.getMinutes() + 30); // set for 30 min
  val.tokenExpiry = expires.getTime();

  ls.set(key, val);
  // console.log('user', ls.get(key));
  return val;
};

export const isExpired = (key) => {
  const obj = ls.get(key);

  const isSessionValid = obj ? (obj.tokenExpiry - (new Date().getTime()) > 0) : false;
  if (isSessionValid) {
    return true;
  } else {
    ls.remove(key);
    return false;
  }
}

/**
 * Fetched obj if its not expired else returns null
 */
export const getAuthUser = (key) => {
  const obj = ls.get(key);
  return obj ? obj : null;
}

export const removeAuthUser = (key) => {
  ls.remove(key);
  return true;
}
