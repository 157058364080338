import React, { Component, Fragment } from "react";
import DataGrid from "../../components/DataGrid/DataGrid";
// import actions
import { exceptionLoad } from "../../actions/reportsAction";

class ExceptionStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      advisor: "ARN-110384",
      rowData: [],
      columnDefs: [
        { headerName: "Client", field: "client_name" },
        { headerName: "Fund", field: "scheme_name" },
        {
          headerName: "Units from",
          children: [
            { headerName: "Transaction", field: "units_from_trans" },
            { headerName: "Summary", field: "units_from_summary" }
          ]
        },
        {
          headerName: "Date of",
          children: [
            {
              headerName: "First Transaction",
              field: "first_transaction_date",
              width: 300
            },
            {
              headerName: "Last Transaction",
              field: "last_transaction_date",
              width: 300
            },
            {
              headerName: "Report Generation",
              field: "report_aum_date",
              width: 250
            }
          ]
        }
      ]
    };
    // Bind the this context to the handler function
  }

  componentDidMount = () => {
    this.handleReconLoad();
  };

  onRowSelected(event) {}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  handleReconLoad = async () => {
    const err = "";
    if (!err) {
      let data = this.state.advisor;
      try {
        const clientDataRes = await exceptionLoad(data);
        const res = clientDataRes.data;
        if (res.status === "success" && res.data !== undefined) {
          this.setState({ rowData: res.data, fetching: true });
        }
      } catch (err) {
        console.error("handleReconLoad, Server err: ", err.response);
      }
    }
  };

  render() {
    const { fetching, rowData } = this.state;
    return (
      <Fragment>
        <div id="recon">
          {!fetching && <div className="text-center">loading...</div>}
          {fetching && rowData.length > 0 && (
            <DataGrid
              ref="idreviewgrid"
              RowDef={rowData}
              onRowSelected={this.onRowSelected.bind(this)}
              onGridReady={this.onGridReady.bind(this)}
              ColumnDef={this.state.columnDefs}
              paginationPageSize="9"
              rowmodeltype="pagination"
              pagination={true}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default ExceptionStats;
