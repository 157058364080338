import React, { Component, Fragment } from "react";
class TransactionTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const isActive = this.props.active;
    return (
      <Fragment>
        <div className="al_tabs">
          <ul className="nav nav-fill">
            <li className="nav-item">
              <div
                className={`nav-link ${isActive === 'purchase' ? 'active' : ''}`}
                onClick={() => this.props.handleTabActive('purchase')}
              >
                <i className="fa fa-suitcase" aria-hidden="true"></i> Purchase
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link ${isActive === 'redemption' ? 'active' : ''}`}
                onClick={() => this.props.handleTabActive('redemption')}
              >
                <i className="fa fa-credit-card" aria-hidden="true"></i> Redemption
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link ${isActive === 'switch' ? 'active' : ''}`}
                onClick={() => this.props.handleTabActive('switch')}
              >
                <i className="fa fa-refresh" aria-hidden="true"></i> Switch
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link ${isActive === 'systematic' ? 'active' : ''}`}
                onClick={() => this.props.handleTabActive('systematic')}
              >
                <i className="fa fa-line-chart" aria-hidden="true"></i> Systematic
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link ${isActive === 'e-mandate' ? 'active' : ''}`}
                onClick={() => this.props.handleTabActive('e-mandate')}
              >
                <i className="fa fa-info" aria-hidden="true"></i> E-Mandate
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link ${isActive === 'cart' ? 'active' : ''}`}
                onClick={() => this.props.handleTabActive('cart')}
              >
                <i className="fa fa-cart-plus" aria-hidden="true"></i> Cart
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link ${isActive === 'history' ? 'active' : ''}`}
                onClick={() => this.props.handleTabActive('history')}
              >
                <i className="fa fa-history" aria-hidden="true"></i> History
              </div>
            </li>
          </ul>
        </div>
      </Fragment>
    )
  }
};

export default TransactionTabs;


