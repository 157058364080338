import React, { Component, Fragment } from "react";
import Button from '../../components/Button/Button';

const clintList = [
  {
    fund: 'ABC',
    type: 'SIP',
    date: '18-04-2019',
    amount: '1000'
  },
  {
    fund: 'XYZ',
    type: 'SWP',
    date: '18-04-2019',
    amount: '1000'
  },
];
class Mandate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <div className="container" id="cart">
          <div>
            <div className="table-responsive mt-4">
              <table className="table table-hover al_table">
                <thead>
                  <tr>
                    <th scope="col">Fund</th>
                    <th scope="col">Type</th>
                    <th scope="col">Initiated On</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Next Step </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    clintList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.fund}</td>
                          <td>{item.type}</td>
                          <td>{item.date}</td>
                          <td>{item.amount}</td>
                          <td>
                            <Button type="button" size="sm" text="Transact" />
                            <span className="ml-3">
                              <Button type="button" size="sm" text="Delete" />
                            </span>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </Fragment>
    )
  }
};

export default Mandate;
