
import React, { Component, Fragment } from "react";
import Modal from 'react-bootstrap/Modal';
import Toastr from 'toastr';
class ReedemSchemeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addToCart = () => {
    Toastr.success('Added to cart', 'Cart');
    this.props.onHide();
  }

  render() {
    return (
      <Fragment>
        <Modal {...this.props} size="lg">
          <Modal.Header closeButton className="al_modal_header">
            <Modal.Title>Redeem Scheme</Modal.Title>
          </Modal.Header>
          <Modal.Body className="al_modal_body">
            <div className="row">
              <label className="ml-3">Scheme: </label>
              <div className="al_scheme_text">Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="redemption_amount">Redemption Amount:</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text al_amount">$</div>
                    </div>
                    <input type="text" className="form-control" id="redemption_amount" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="indicative_value">Indicative Value:</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text al_amount">$</div>
                    </div>
                    <input type="text" className="form-control" id="indicative_value" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <label className="ml-3">Redeem By Units:</label>
              <div className="col-md-12">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="all" value="all" />
                  <label class="form-check-label" htmlFor="all">All</label>
                </div>
                <div class="form-check form-check-inline ml-3">
                  <input class="form-check-input" type="checkbox" id="units" value="units" />
                  <label class="form-check-label" htmlFor="units">Units</label>
                  <input type="text" className="form-control ml-2" />
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-5">
              <button
                type="button"
                className="btn btn-info"
                onClick={this.addToCart}
              >
                <i class="fa fa-cart-plus al_cart"></i>
              </button>
              <button
                type="button"
                className="btn btn-info ml-4"
                onClick={this.props.onHide}
              >
                <i class="fa fa-times al_close"></i>
            </button>
            </div>

          </Modal.Body>
        </Modal>
      </Fragment>
    )
  }
};

export default ReedemSchemeModal;
