import React, { Fragment, Component } from "react";
import Header from "../../components/Header/Header";
import Dropdown from "../../components/Dropdown/Dropdown";
import Toastr from "toastr";

class AOForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [
        {
          ifsc: "",
          bname: "",
          aNumber: "",
          aType: "",
          branch: "",
          bpincode: "",
          bcity: "",
          bstate: "",
          ifscErr: false,
          bnameErr: false,
          aNumberErr: false,
          aTypeErr: false,
          branchErr: false,
          bpincodeErr: false,
          bcityErr: false,
          bstateErr: false
        }
      ],
      accountType: [
        { value: "Savings", id: 1 },
        { value: "Current", id: 2 }
      ]
    };
  }

  componentWillMount = () => {
    let fields = this.state.rows;
    let field = this.state.rows[0];
    field["ifsc"] = this.props.data["ifsc"];
    field["bname"] = this.props.data["bName"];
    field["aNumber"] = this.props.data["aNumber"];
    fields[0] = field;
    this.setState({ rows: fields });
  };

  handleChange = idx => e => {
    const name = e.target.name;
    const value = e.target.value;

    let items = this.state.rows;
    const item = this.state.rows[idx];
    item[name] = value;
    items[idx] = item;

    this.setState({ rows: items });
  };

  handleAddRow = () => {
    const item = {
      ifsc: "",
      bname: "",
      aNumber: "",
      aType: "",
      branch: "",
      bpincode: "",
      bcity: "",
      bstate: "",
      ifscErr: false,
      bnameErr: false,
      aNumberErr: false,
      aTypeErr: false,
      branchErr: false,
      bpincodeErr: false,
      bcityErr: false,
      bstateErr: false
    };

    this.setState(
      {
        rows: [...this.state.rows, item]
      },
      () => console.log(this.state.rows)
    );
  };

  handleRemoveSpecificRow = idx => () => {
    if (idx !== 0) {
      const rows = [...this.state.rows];
      rows.splice(idx, 1);
      this.setState({ rows });
      console.log({ rows });
    }
  };

  keyUpHandler = idx => e => {
    const refName = e.target.name;
    console.log(refName);
    const x = e.target.value;
    let items = this.state.rows;
    const item = this.state.rows[idx];

    if (refName === "ifsc") {
      if (!x.match(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)) {
        item[refName + "Err"] = true;
        items[idx] = item;

        this.setState({ rows: items });

        Toastr.clear();
        Toastr.error("Valid IFSC is required.");
      } else {
        item[refName + "Err"] = false;
        items[idx] = item;

        this.setState({ rows: items });
        Toastr.clear();
      }
    }
    if (
      refName === "bname" ||
      refName === "branch" ||
      refName === "bcity" ||
      refName === "bstate"
    ) {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        item[refName + "Err"] = true;
        items[idx] = item;

        this.setState({ rows: items });

        Toastr.clear();
        Toastr.error("Valid IFSC is required.");
      } else {
        item[refName + "Err"] = false;
        items[idx] = item;

        this.setState({ rows: items });
        Toastr.clear();
      }
    }
    if (refName === "bpincode") {
      if (!x.match(/^\d{6}$/)) {
        item[refName + "Err"] = true;
        items[idx] = item;

        this.setState({ rows: items });

        Toastr.clear();
        Toastr.error("Valid pincode is required.");
      } else {
        item[refName + "Err"] = false;
        items[idx] = item;

        this.setState({ rows: items });
        Toastr.clear();
      }
    }
    if (refName === "aNumber") {
      if (!x.match(/^\d{9,18}$/)) {
        item[refName + "Err"] = true;
        items[idx] = item;

        this.setState({ rows: items });

        Toastr.clear();
        Toastr.error("Valid account number is required.");
      } else {
        item[refName + "Err"] = false;
        items[idx] = item;

        this.setState({ rows: items });
        Toastr.clear();
      }
    }
  };

  handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let l = this.state.rows.length;
    for (let i = 0; i < l; i++) {
      let fields = this.state.rows[i];

      if (typeof fields["ifsc"] !== "undefined") {
        if (!fields["ifsc"].match(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)) {
          formIsValid = false;
          errors["address1"] = "Valid IFSC is required.";
          Toastr.error("Valid IFSC is required.");
        }
      }
      if (typeof fields["bname"] !== "undefined") {
        if (!fields["bname"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["bname"] = "Valid bank name is required.";
          Toastr.error("Valid bank name is required.");
        }
      }
      if (typeof fields["branch"] !== "undefined") {
        if (!fields["branch"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["branch"] = "Valid branch is required.";
          Toastr.error("Valid branch is required.");
        }
      }
      if (typeof fields["bcity"] !== "undefined") {
        if (!fields["bcity"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["bcity"] = "Valid city is required.";
          Toastr.error("Valid city is required.");
        }
      }
      if (typeof fields["bpincode"] !== "undefined") {
        if (!fields["bpincode"].match(/^\d{6}$/)) {
          formIsValid = false;
          errors["bpincode"] = "Valid pincode is required.";
          Toastr.error("Valid pincode is required.");
        }
      }

      if (typeof fields["bstate"] !== "undefined") {
        if (!fields["bstate"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["bstate"] = "Valid state is required.";
          Toastr.error("Valid state is required.");
        }
      }
      if (typeof fields["aNumber"] !== "undefined") {
        if (!fields["aNumber"].match(/^\d{9,18}$/)) {
          formIsValid = false;
          errors["aNumber"] = "Valid account number is required.";
          Toastr.error("Valid account number is required.");
        }
      }
    }
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
    } else {
      Toastr.error("Form has errors.", "Form has errors.");
    }
  }

  render() {
    return (
      <Fragment>
        <Header userRole="user" />
        <div className="col-md-12 col-offset-6 bg-lighter px-4 pt-2 padding-top-bottom">
          <form
            className="col-md-12 needs-validation"
            onSubmit={this.handleSubmit}
          >
            {this.state.rows.map((item, idx) => (
              <div id="id" key={idx}>
                <div className="row">
                  <div className="col-md-11">
                    <label htmlFor={"bank-$" + idx}>{`Bank #${idx + 1}`}</label>
                  </div>
                  <div className="col-md-1 form-submit">
                    <input
                      type="submit"
                      value="Delete"
                      onClick={this.handleRemoveSpecificRow(idx)}
                      className={
                        idx > 0
                          ? "btn btn-danger btn-md mx-1 px-5 button-width right"
                          : "hidden"
                      }
                      name="submit"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="ifsc"
                        id="ifsc"
                        required
                        value={this.state.rows[idx].ifsc}
                        data-id={idx}
                        MaxLength="11"
                        type="text"
                        ref="ifsc"
                        className={
                          this.state.rows[idx].ifscErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                        onChange={this.handleChange(idx)}
                      />
                      <label for="ifsc" className="form-label">
                        IFSC Code
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="bname"
                        id="bname"
                        required
                        value={this.state.rows[idx].bname}
                        data-id={idx}
                        MaxLength="25"
                        type="text"
                        ref="bname"
                        className={
                          this.state.rows[idx].bnameErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                        onChange={this.handleChange(idx)}
                      />
                      <label for="bname" className="form-label">
                        Bank Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <Dropdown
                        onOptionClick={this.handleChange(idx)}
                        default={"Account Type"}
                        dataid={idx}
                        id="aType"
                        name="aType"
                        selected={this.state.accountType.id}
                        opts={this.state.accountType}
                        field_attr="id"
                        value_attr="value"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="aNumber"
                        id="aNumber"
                        required
                        value={this.state.rows[idx].aNumber}
                        data-id={idx}
                        MaxLength="11"
                        type="text"
                        ref="aNumber"
                        className={
                          this.state.rows[idx].aNumberErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                        onChange={this.handleChange(idx)}
                      />
                      <label for="aNumber" className="form-label">
                        Account Number
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row row-margin">
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="branch"
                        id="branch"
                        required
                        value={this.state.rows[idx].branch}
                        data-id={idx}
                        MaxLength="11"
                        type="text"
                        ref="branch"
                        className={
                          this.state.rows[idx].branchErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                        onChange={this.handleChange(idx)}
                      />
                      <label for="branch" className="form-label">
                        Branch
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="bcity"
                        id="bcity"
                        required
                        value={this.state.rows[idx].bcity}
                        data-id={idx}
                        MaxLength="11"
                        type="text"
                        ref="bcity"
                        className={
                          this.state.rows[idx].bcityErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                        onChange={this.handleChange(idx)}
                      />
                      <label for="bcity" className="form-label">
                        City
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="bpincode"
                        id="bpincode"
                        required
                        value={this.state.rows[idx].bpincode}
                        data-id={idx}
                        MaxLength="11"
                        type="text"
                        ref="bpincode"
                        className={
                          this.state.rows[idx].bpincodeErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                        onChange={this.handleChange(idx)}
                      />
                      <label for="bpincode" className="form-label">
                        Pincode
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group form-input">
                      <input
                        name="bstate"
                        id="bstate"
                        value={this.state.rows[idx].bstate}
                        data-id={idx}
                        required
                        MaxLength="11"
                        type="text"
                        ref="bstate"
                        className={
                          this.state.rows[idx].bstateErr ? "error" : "success"
                        }
                        onBlur={this.keyUpHandler(idx)}
                        onChange={this.handleChange(idx)}
                      />
                      <label for="bstate" className="form-label">
                        State
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="form-submit">
              <input
                type="submit"
                value="Add"
                onClick={this.handleAddRow}
                className="btn btn-md mx-1 btn-dark px-5 button-width"
                id="submit"
                name="submit"
              />
            </div>
            <hr />
            <div className="form-submit">
              <input
                type="submit"
                value="Next"
                className="btn btn-md mx-1 btn-warning px-5 button-width right"
                id="submit"
                name="submit"
              />
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default AOForm;
