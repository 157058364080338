import React, { Fragment, Component } from "react";
import DataGrid from "../../components/DataGrid/DataGrid";
import Modal from "react-bootstrap/Modal";

function isFirstColumn(params) {
  let displayedColumns = params.columnApi.getAllDisplayedColumns();
  let thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

class RTADataSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToNewPage: false,
      clientData: this.props.modal,
      modalData: {},
      showdata: this.displayData,
      postVal: "",
      fields: {},
      contactsData: [],
      contactsColumnDefs: [
        {
          headerName: "",
          field: "",
          checkboxSelection: true,
          suppressSizeToFit: true,
          width: 60,
          suppressSorting: true,
          suppressFilter: true
        },
        { headerName: "AddressId", field: "address_id", hide: true },
        { headerName: "Email", field: "email", width: 150 },
        { headerName: "Mobile Number", field: "mobile_number", width: 150 }
      ],
      addressData: [],
      addressColumnDefs: [
        {
          headerName: "",
          field: "",
          checkboxSelection: true,
          suppressSizeToFit: true,
          width: 60,
          suppressSorting: true,
          suppressFilter: true
        },
        { headerName: "AddressId", field: "address_id", hide: true },
        { headerName: "Address", field: "address", width: 400 },
        { headerName: "City", field: "city", width: 250 },
        { headerName: "Pincode", field: "pin_code", width: 250 },
        { headerName: "State", field: "state", width: 250 },
        { headerName: "Country", field: "country", width: 250 }
      ],
      bankData: [],
      bankColumnDefs: [
        {
          headerName: "",
          field: "",
          checkboxSelection: true,
          suppressSizeToFit: true,
          width: 60,
          suppressSorting: true,
          suppressFilter: true
        },
        { headerName: "Account Info ID", field: "account_info_id", hide: true },
        { headerName: "Bank Name", field: "bank_name", width: 500 },
        { headerName: "Account Number", field: "bank_ac_number", width: 450 },
        { headerName: "IFSC code", field: "bank_ifsc_code", width: 450 }
      ],
      nomineeData: [],
      nomineeColumnDefs: [
        {
          headerName: "",
          field: "",
          checkboxSelection: true,
          suppressSizeToFit: true,
          width: 60,
          suppressSorting: true,
          suppressFilter: true
        },
        { headerName: "Nominee Name", field: "nominee_name", width: 200 },
        {
          headerName: "Nominee Relation",
          field: "nominee_relation",
          width: 200
        },
        { headerName: "Address", field: "nominee_address", width: 200 },
        { headerName: "City", field: "nominee_city", width: 200 },
        { headerName: "State", field: "nominee_state", width: 200 },
        { headerName: "Pincode", field: "nominee_pincode", width: 200 },
        { headerName: "Percent", field: "nominee_percent", width: 150 }
      ],
      autoGroupColumnDef: {
        headerName: "Name",
        field: "name",
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true }
      },
      defaultColDef: {
        checkboxSelection: isFirstColumn
      }
    };
  }

  componentDidMount = () => {
    if (this.props.modal !== null && this.props.modal[0] !== undefined) {
      if (
        this.props.modal[0].addresses !== undefined ||
        this.props.modal[0].addresses !== null
      ) {
        this.setState(
          { addressData: this.props.modal[0].addresses },
          function() {}
        );
      }

      if (
        this.props.modal[0].accounts !== undefined ||
        this.props.modal[0].accounts !== null
      ) {
        this.setState(
          { bankData: this.props.modal[0].accounts },
          function() {}
        );
      }

      if (
        this.props.modal[0].nominees !== undefined ||
        this.props.modal[0].nominees !== null
      ) {
        this.setState(
          { nomineeData: this.props.modal[0].nominees },
          function() {}
        );
      }
    }
  };

  shouldComponentUpdate = nextProps => {
    if (nextProps.date !== this.props.date) {
      this.componentDidMount();
    }
    return true;
  };

  onRowSelected(idx, event) {
    if (event.node.selected) {
      let fields = this.state.fields;

      if (event.node.data.email !== undefined && idx === 0) {
        fields["email"] = event.node.data.email;
        fields["mobile"] = event.node.data.mobile_number;
      } else if (event.node.data.email !== undefined && idx === 1) {
        fields["email1"] = event.node.data.email;
        fields["mobile1"] = event.node.data.mobile_number;
      } else if (event.node.data.email !== undefined && idx === 2) {
        fields["email2"] = event.node.data.email;
        fields["mobile2"] = event.node.data.mobile_number;
      } else if (event.node.data.address !== undefined) {
        fields["address1"] = event.node.data.address;
        fields["city"] = event.node.data.city;
        fields["pin_code"] = event.node.data.pin_code;
        fields["state"] = event.node.data.state;
        fields["country"] = event.node.data.country;
      } else if (event.node.data.bank_name !== undefined) {
        fields["bName"] = event.node.data.bank_name;
        fields["aNumber"] = event.node.data.bank_ac_number;
        fields["ifsc"] = event.node.data.bank_ifsc_code;
      } else if (event.node.data.nominee_name !== undefined) {
        fields["nominee_name"] = event.node.data.nominee_name;
        fields["nominee_relation"] = event.node.data.nominee_relation;
        fields["nominee_address"] = event.node.data.nominee_address;
        fields["nominee_city"] = event.node.data.nominee_city;
        fields["nominee_state"] = event.node.data.nominee_state;
        fields["nominee_pincode"] = event.node.data.nominee_pincode;
        fields["nominee_percent"] = event.node.data.nominee_percent;
      }
      this.setState({ fields });
    } else {
      let fields = this.state.fields;
      if (event.node.data.email === undefined && idx === 0) {
        fields["email"] = null;
        fields["mobile"] = null;
      } else if (event.node.data.email === undefined && idx === 1) {
        fields["email1"] = null;
        fields["mobile1"] = null;
      } else if (event.node.data.email === undefined && idx === 2) {
        fields["email2"] = null;
        fields["mobile2"] = null;
      } else if (event.node.data.address === undefined) {
        fields["address1"] = null;
        fields["city"] = null;
        fields["pin_code"] = null;
        fields["state"] = null;
        fields["country"] = null;
      } else if (event.node.data.bank_name === undefined) {
        fields["bName"] = null;
        fields["aNumber"] = null;
        fields["bank_ifsc_code"] = null;
      } else if (event.node.data.nominee_name === undefined) {
        fields["nominee_name"] = null;
        fields["nominee_relation"] = null;
        fields["nominee_address"] = null;
        fields["nominee_city"] = null;
        fields["nominee_state"] = null;
        fields["nominee_pincode"] = null;
        fields["nominee_percent"] = null;
      }
      this.setState({ fields });
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  handleSubmit = e => {
    e.preventDefault();

    let fields = this.state.fields;
    if (this.props.modal !== null && this.props.modal[0] !== undefined) {
      fields["name"] = this.props.modal[0].client_name;
      fields["fatherSpouseName"] = this.props.modal[0].father_name;
      fields["aadhaar"] = this.props.modal[0].aadhar_number;
      fields["mobile"] = this.state.fields["mobile"];
      fields["email"] = this.state.fields["email"];
    }

    if (
      this.props.modal !== null &&
      this.props.modal[0] !== undefined &&
      this.props.modal[1] !== undefined
    ) {
      // 2nd Applicant
      fields["name1"] = this.props.modal[1].client_name;
      fields["fatherSpouseName1"] = this.props.modal[1].father_name;
      fields["aadhaar1"] = this.props.modal[1].aadhar_number;
      fields["mobile1"] = this.state.fields["mobile1"];
      fields["email1"] = this.state.fields["email1"];
    }

    if (
      this.props.modal !== null &&
      this.props.modal[0] !== undefined &&
      this.props.modal[1] !== undefined &&
      this.props.modal[2] !== undefined
    ) {
      //3rd Applicant
      fields["name2"] = this.props.modal[2].client_name;
      fields["fatherSpouseName2"] = this.props.modal[2].father_name;
      fields["aadhaar2"] = this.props.modal[2].aadhar_number;
      fields["mobile2"] = this.state.fields["mobile2"];
      fields["email2"] = this.state.fields["email2"];
    }

    this.setState({ fields }, function() {
      this.props.handleSubmit(e, fields);
      console.log(JSON.stringify(fields));
    });
  };

  render() {
    return (
      <Fragment>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          size="lg"
          className="modal-width"
          id="rtaDataSelection"
        >
          <Modal.Header closeButton className="al_modal">
            <Modal.Title>RTA Data Selection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit}>
              <div className="modal-body">
                <div id="accordion">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <a
                          class="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          href
                        >
                          Contacts
                        </a>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        {this.state.clientData.map((item, idx) => (
                          <div>
                            <label htmlFor={idx}>{`#${idx +
                              1} Applicant`}</label>
                            <DataGrid
                              ref="idreviewgrid"
                              RowDef={this.state.clientData[idx].contacts}
                              onRowSelected={this.onRowSelected.bind(this, idx)}
                              onGridReady={this.onGridReady.bind(this)}
                              ColumnDef={this.state.contactsColumnDefs}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <a
                          class="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                          href
                        >
                          Address
                        </a>
                      </h5>
                    </div>

                    <div
                      id="collapseTwo"
                      class="collapse hide"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <DataGrid
                          ref="idreviewgridaddr"
                          RowDef={this.state.addressData}
                          onRowSelected={this.onRowSelected.bind(this, 0)}
                          onGridReady={this.onGridReady.bind(this)}
                          ColumnDef={this.state.addressColumnDefs}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <a
                          class="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                          href
                        >
                          Accounts
                        </a>
                      </h5>
                    </div>

                    <div
                      id="collapseThree"
                      class="collapse hide"
                      aria-labelledby="headingThree"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <DataGrid
                          ref="idreviewgrid"
                          RowDef={this.state.bankData}
                          onRowSelected={this.onRowSelected.bind(this, 0)}
                          onGridReady={this.onGridReady.bind(this)}
                          ColumnDef={this.state.bankColumnDefs}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h5 class="mb-0">
                        <a
                          class="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                          href
                        >
                          Nominee
                        </a>
                      </h5>
                    </div>

                    <div
                      id="collapseFour"
                      class="collapse hide"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <DataGrid
                          ref="idreviewgrid"
                          RowDef={this.state.nomineeData}
                          onRowSelected={this.onRowSelected.bind(this, 0)}
                          onGridReady={this.onGridReady.bind(this)}
                          ColumnDef={this.state.nomineeColumnDefs}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer py-2 border-0">
                <div class="form-submit">
                  <input
                    type="submit"
                    class="btn btn-sm mx-1 btn-warning px-2 button-width pull-right"
                    id="submit"
                    name="submit"
                    value="Submit"
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default RTADataSelection;
