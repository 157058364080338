import config from "../config";
import { apiCalls } from "./apiCalls";

export const addressLoad = advisor_id => {
  const url = config.base_url + `/investor/client_secondary_info_address`;

  const reqObject = {
    advisor_id: advisor_id
  };

  return apiCalls("post", url, reqObject);
};

export const bankLoad = advisor_id => {
  const url = config.base_url + `/investor/client_secondary_info_bank`;

  const reqObject = {
    advisor_id: advisor_id
  };

  return apiCalls("post", url, reqObject);
};

export const nominationLoad = advisor_id => {
  const url = config.base_url + `/investor/client_nominations`;

  const reqObject = {
    advisor_id: advisor_id
  };

  return apiCalls("post", url, reqObject);
};

export const processingLoad = advisor_id => {
  const url = config.base_url + `/rta_report/rta_processing_stats`;

  const reqObject = {
    advisor_id: advisor_id
  };

  return apiCalls("post", url, reqObject);
};

export const exceptionLoad = advisor_id => {
  const url = config.base_url + `/rta_report/rta_exception_stats`;

  const reqObject = {
    advisor_id: advisor_id
  };

  return apiCalls("post", url, reqObject);
};
