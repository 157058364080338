import React, { Fragment, Component } from "react";
import Header from "../../components/Header/Header";
import Toastr from "toastr";

class AOForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: this.props.data,
      errors: {
        address1: false,
        address2: false,
        address3: false,
        address4: false,
        city: false,
        state: false,
        pincode: false,
        country: false
      }
    };

    this.handleAddress1KeyUp = this.onBlurHandler.bind(this, "address1");
    this.handleAddress2KeyUp = this.onBlurHandler.bind(this, "address2");
    this.handleAddress3KeyUp = this.onBlurHandler.bind(this, "address3");
    this.handleAddress4KeyUp = this.onBlurHandler.bind(this, "address4");
    this.handleCityKeyUp = this.onBlurHandler.bind(this, "city");
    this.handleStateKeyUp = this.onBlurHandler.bind(this, "state");
    this.handleCountryKeyUp = this.onBlurHandler.bind(this, "country");
    this.handlePincodeKeyUp = this.onBlurHandler.bind(this, "pincode");
  }

  onBlurHandler(refName, e) {
    console.log(refName);
    const x = e.target.value;
    console.log();
    if (
      refName === "address1" ||
      refName === "address2" ||
      refName === "address3" ||
      refName === "address4"
    ) {
      if (!x.match(/^[a-zA-Z0-9 ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid " + [refName] + " is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (refName === "city" || refName === "state" || refName === "country") {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid " + [refName] + " is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (refName === "pincode") {
      if (!x.match(/^\d{6}$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid pincode is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
  }

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (typeof fields["address1"] !== "undefined") {
      if (!fields["address1"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["address1"] = "Valid address1 is required.";
        Toastr.error("Valid address1 is required.");
      }
    }
    if (typeof fields["address2"] !== "undefined") {
      if (!fields["address2"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["address2"] = "Valid address2 is required.";
        Toastr.error("Valid address2 is required.");
      }
    }
    if (typeof fields["address3"] !== "undefined") {
      if (!fields["address3"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["address3"] = "Valid address3 is required.";
        Toastr.error("Valid address3 is required.");
      }
    }
    if (typeof fields["address4"] !== "undefined") {
      if (!fields["address4"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["address4"] = "Valid address4 is required.";
        Toastr.error("Valid address4 is required.");
      }
    }
    if (typeof fields["pincode"] !== "undefined") {
      if (!fields["pincode"].match(/^\d{6}$/)) {
        formIsValid = false;
        errors["pincode"] = "Valid pincode is required.";
        Toastr.error("Valid pincode is required.");
      }
    }
    if (typeof fields["city"] !== "undefined") {
      if (!fields["city"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["city"] = "Valid city is required.";
        Toastr.error("Valid city is required.");
      }
    }
    if (typeof fields["state"] !== "undefined") {
      if (!fields["state"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["state"] = "Valid state is required.";
        Toastr.error("Valid state is required.");
      }
    }
    if (typeof fields["country"] !== "undefined") {
      if (!fields["country"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["country"] = "Valid country is required.";
        Toastr.error("Valid country is required.");
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange = field => e => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      event.target.className += " needs-validation";
      const data = {
        addressLine1: this.state.fields["address1"],
        addressLine2: this.state.fields["address2"],
        addressLine3: this.state.fields["address3"],
        addressLine4: this.state.fields["address4"],
        city: this.state.fields["city"],
        state: this.state.fields["state"],
        pincode: this.state.fields["pincode"],
        country: this.state.fields["country"]
      };
      console.log(JSON.stringify(data));
    } else {
      event.target.className += " was-validated";
      Toastr.error("Form has errors.", "Form has errors.");
    }
  }

  render() {
    return (
      <Fragment>
        <Header userRole="user" />
        <div className="col-md-12 col-offset-6 bg-lighter px-4 pt-2 padding-top-bottom">
          <form
            className="col-md-12 needs-validation"
            onSubmit={this.handleSubmit}
          >
            <div className="row">
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="address1"
                    id="address1"
                    MaxLength="25"
                    required
                    className={this.state.errors.address1 ? "error" : "success"}
                    value={this.state.fields["address1"]}
                    type="text"
                    ref="address1"
                    onBlur={this.handleAddress1KeyUp}
                    onChange={() => this.handleChange("address1")}
                  />
                  <label for="address1" className="form-label">
                    Address Line 1
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="address2"
                    id="address2"
                    required
                    MaxLength="25"
                    className={this.state.errors.address2 ? "error" : "success"}
                    value={this.state.fields["address2"]}
                    type="text"
                    ref="address2"
                    onBlur={this.handleAddress2KeyUp}
                    onChange={() => this.handleChange("address2")}
                  />
                  <label for="address2" className="form-label">
                    Address Line 2
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="address3"
                    id="address3"
                    MaxLength="25"
                    required
                    className={this.state.errors.address3 ? "error" : "success"}
                    value={this.state.fields["address3"]}
                    type="text"
                    ref="address3"
                    onBlur={this.handleAddress3KeyUp}
                    onChange={() => this.handleChange("address3")}
                  />
                  <label for="address3" className="form-label">
                    Address Line 3
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="address4"
                    id="address4"
                    MaxLength="25"
                    required
                    className={this.state.errors.address4 ? "error" : "success"}
                    value={this.state.fields["address4"]}
                    type="text"
                    ref="address4"
                    onBlur={this.handleAddress4KeyUp}
                    onChange={() => this.handleChange("address4")}
                  />
                  <label for="address4" className="form-label">
                    Address Line 4
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="city"
                    id="city"
                    required
                    MaxLength="15"
                    className={this.state.errors.city ? "error" : "success"}
                    value={this.state.fields["city"]}
                    type="text"
                    ref="city"
                    onBlur={this.handleCityKeyUp}
                    onChange={() => this.handleChange("city")}
                  />
                  <label for="city" className="form-label">
                    City
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="state"
                    id="state"
                    required
                    className={this.state.errors.state ? "error" : "success"}
                    value={this.state.fields["state"]}
                    type="text"
                    ref="state"
                    onBlur={this.handleStateKeyUp}
                    onChange={() => this.handleChange("state")}
                  />
                  <label for="state" className="form-label">
                    State
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="pincode"
                    id="pincode"
                    required
                    className={this.state.errors.pincode ? "error" : "success"}
                    value={this.state.fields["pincode"]}
                    type="text"
                    ref="pincode"
                    onBlur={this.handlePincodeKeyUp}
                    onChange={() => this.handleChange("pincode")}
                  />
                  <label for="pincode" className="form-label">
                    Pin Code
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="country"
                    id="country"
                    required
                    className={this.state.errors.country ? "error" : "success"}
                    value={this.state.fields["country"]}
                    type="text"
                    ref="country"
                    onBlur={this.handleCountryKeyUp}
                    onChange={() => this.handleChange("country")}
                  />
                  <label for="country" className="form-label">
                    Country
                  </label>
                </div>
              </div>
            </div>

            <div className="form-submit">
              <input
                type="submit"
                value="Next"
                className="btn btn-md mx-1 btn-warning px-5 button-width right"
                id="submit"
                name="submit"
              />
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default AOForm;
