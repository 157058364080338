import React, { Fragment, Component } from "react";
import Header from "../../components/Header/Header";
import Toastr from "toastr";
import Dropdown from "../../components/Dropdown/Dropdown";

class ClientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountTypeData: this.props.accountTypeData,
      kycVerification: this.props.kycVerification,
      genderStyle: "",
      maritalStyle: "",
      display1joint: false,
      display2joint: false,
      displayKYCVerification: false,
      displayGuardian: false,
      fields: this.props.data,
      nextTab: this.props.nextTab,
      gender: [
        { value: "Male", id: 1 },
        { value: "Female", id: 2 },
        { value: "Others", id: 3 }
      ],
      genderValue: "",
      maritalStatus: [
        { value: "Married", id: 1 },
        { value: "unMarried", id: 2 },
        { value: "Others", id: 3 }
      ],
      maritalStatusValue: "",
      errors: {
        name: false,
        email: false,
        mobile: false,
        fname: false,
        guardian: false,
        aadhaar: false,
        name1: false,
        email1: false,
        mobile1: false,
        fname1: false,
        guardian1: false,
        aadhaar1: false,
        name2: false,
        email2: false,
        mobile2: false,
        fname2: false,
        guardian2: false,
        aadhaar2: false
      }
    };

    this.handleNameKeyUp = this.onBlurHandler.bind(this, "name");
    this.handleEmailKeyUp = this.onBlurHandler.bind(this, "email");
    this.handleMobileKeyUp = this.onBlurHandler.bind(this, "mobile");
    this.handleAadhaarKeyUp = this.onBlurHandler.bind(this, "aadhaar");
    this.handlefNameKeyUp = this.onBlurHandler.bind(this, "fname");
    this.handleGNameKeyUp = this.onBlurHandler.bind(this, "guardian");

    this.handleName1KeyUp = this.onBlurHandler.bind(this, "name1");
    this.handleEmail1KeyUp = this.onBlurHandler.bind(this, "email1");
    this.handleMobile1KeyUp = this.onBlurHandler.bind(this, "mobile1");
    this.handleAadhaar1KeyUp = this.onBlurHandler.bind(this, "aadhaar1");
    this.handlefName1KeyUp = this.onBlurHandler.bind(this, "fname1");
    this.handleGName1KeyUp = this.onBlurHandler.bind(this, "guardian1");

    this.handleName2KeyUp = this.onBlurHandler.bind(this, "name2");
    this.handleEmail2KeyUp = this.onBlurHandler.bind(this, "email2");
    this.handleMobile2KeyUp = this.onBlurHandler.bind(this, "mobile2");
    this.handleAadhaar2KeyUp = this.onBlurHandler.bind(this, "aadhaar2");
    this.handlefName2KeyUp = this.onBlurHandler.bind(this, "fname2");
    this.handleGName2KeyUp = this.onBlurHandler.bind(this, "guardian2");
  }

  componentWillMount = () => {
    switch (this.state.accountTypeData.joint) {
      case "1":
        this.setState({ display1joint: true }, function() {});
        break;
      case "2":
        this.setState({ display2joint: true }, function() {});
        break;
      default:
        this.setState({ display1joint: false });
        this.setState({ display2joint: false });
        break;
    }

    switch (this.state.accountTypeData.accountType) {
      case "4":
        this.setState({ displayGuardian: true }, function() {});
        break;
      default:
        this.setState({ displayGuardian: false });
        break;
    }

    switch (this.state.kycVerification.kyc) {
      case true:
        this.setState({ displayKYCVerification: true });
        break;
      default:
        this.setState({ displayKYCVerification: false });
        break;
    }
  };

  onBlurHandler(refName, e) {
    console.log(refName);
    const x = e.target.value;
    console.log();
    if (refName === "name" || refName === "name1" || refName === "name2") {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid Name is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (refName === "email" || refName === "email1" || refName === "email2") {
      if (!x.match(/^(\w+?@\w+?\x2E.+)$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid Email is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (
      refName === "mobile" ||
      refName === "mobile1" ||
      refName === "mobile2"
    ) {
      if (!x.match(/^(\+?\d[\d -]{8,12}\d)$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid mobile No. is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (
      refName === "aadhaar" ||
      refName === "aadhaar1" ||
      refName === "aadhaar2"
    ) {
      if (!x.match(/^(^\d{4}\s\d{4}\s\d{4}$)$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid Aadhaar No. is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
    if (refName === "fname" || refName === "fname1" || refName === "fname2") {
      if (!x.match(/^[a-zA-Z ]*$/)) {
        this.setState({ errors: { ...this.state.errors, [refName]: true } });
        Toastr.clear();
        Toastr.error("Valid Name is required.");
      } else {
        Toastr.clear();
        this.setState({ errors: { ...this.state.errors, [refName]: false } });
      }
    }
  }

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //applicant name
    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "Valid Name is required.";
        Toastr.error("Valid Name is required.");
      }
    }
    if (typeof fields["email"] !== "undefined") {
      if (!fields["email"].match(/^(\w+?@\w+?\x2E.+)$/)) {
        formIsValid = false;
        errors["email"] = "Valid email is required.";
        Toastr.error("Valid email is required.");
      }
    }
    if (typeof fields["mobile"] !== "undefined") {
      if (!fields["mobile"].match(/^(\+?\d[\d -]{8,12}\d)$/)) {
        formIsValid = false;
        errors["mobile"] = "Valid mobile No. is required.";
        Toastr.error("Valid mobile No. is required.");
      }
    }
    if (typeof fields["aadhaar"] !== "undefined") {
      if (!fields["aadhaar"].match(/^(^\d{4}\s\d{4}\s\d{4}$)$/)) {
        formIsValid = false;
        errors["aadhaar"] = "Valid aadhaar No. is required.";
        Toastr.error("Valid aadhaar No. is required.");
      }
    }
    if (typeof fields["fname"] !== "undefined") {
      if (!fields["fname"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["fname"] = "Valid Name is required.";
        Toastr.error("Valid Name is required.");
      }
    }
    if (typeof fields["guardian"] !== "undefined") {
      if (!fields["guardian"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["guardian"] = "Valid Guardian Name is required.";
        Toastr.error("Valid Guardian Name is required.");
      }
    }
   // this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange = field => e => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      event.target.className += " needs-validation";
      const data = {
        applicantName: this.state.fields["name"],
        fsName: this.state.fields["fatherSpouseName"],
        mobile: this.state.fields["mobile"],
        email: this.state.fields["email"],
        guardian: this.state.fields["guardian"],
        aadhaar: this.state.fields["aadhaar"],
        ckycNo: this.state.fields["ckycNo"],
        nationality: this.state.fields["nationality"],
        gender: this.state.fields["gender"],
        maritalStatus: this.state.fields["maritalStatus"],
        secondApplicant: {
          applicantName: this.state.fields["name1"],
          fsName: this.state.fields["fatherSpouseName1"],
          mobile: this.state.fields["mobile1"],
          email: this.state.fields["email1"],
          guardian: this.state.fields["guardian1"],
          aadhaar: this.state.fields["aadhaar1"],
          ckycNo: this.state.fields["ckycNo1"],
          nationality: this.state.fields["nationality1"],
          gender: this.state.fields["gender1"],
          maritalStatus: this.state.fields["maritalStatus1"]
        },
        thirdApplicant: {
          applicantName: this.state.fields["name2"],
          fsName: this.state.fields["fatherSpouseName2"],
          mobile: this.state.fields["mobile2"],
          email: this.state.fields["email2"],
          guardian: this.state.fields["guardian2"],
          aadhaar: this.state.fields["aadhaar2"],
          ckycNo: this.state.fields["ckycNo2"],
          nationality: this.state.fields["nationality2"],
          gender: this.state.fields["gender2"],
          maritalStatus: this.state.fields["maritalStatus2"]
        }
      };
      console.log(JSON.stringify(data));

      this.props.action();
    } else {
      event.target.className += " was-validated";
      Toastr.error("Form has errors.", "Form has errors.");
    }
  }

  genderClick = event => {
    let fields = this.state.fields;
    fields["gender"] = event.target.value;
    this.setState({ fields }, () => {});
  };

  maritalStatusClick = event => {
    let fields = this.state.fields;
    fields["maritalStatus"] = event.target.value;
    this.setState({ fields }, () => {});
  };

  render() {
    let gender, maritalStatus, nationality, guardian;
    let secondApplicant, thirdApplicant;
    if (this.state.displayGuardian) {
      guardian = (
        <div className="col-md-3">
          <div className="form-group form-input">
            <input
              name="guardian"
              id="guardian"
              MaxLength="25"
              required
              className={this.state.errors.guardian ? "error" : "success"}
              value={this.state.fields["guardian"]}
              type="text"
              ref="guardian"
              onBlur={this.handleGNameKeyUp}
              onChange={() => this.handleChange("guardian")}
            />
            <label for="guardian" className="form-label">
              Name of Guardian
            </label>
          </div>
        </div>
      );
    }
    if (this.state.displayKYCVerification) {
      gender = (
        <div className="col-md-3">
          <div className="form-group form-input">
            <Dropdown
              required
              onOptionClick={this.genderClick}
              default={"Gender"}
              dataid="0"
              selected={this.state.gender.id}
              opts={this.state.gender}
              field_attr="id"
              value_attr="value"
            />
          </div>
        </div>
      );
      maritalStatus = (
        <div
          className={
            this.state.displayGuardian ? "col-md-3" : "col-md-3 row-margin"
          }
        >
          <div className="form-group form-input">
            <Dropdown
              required
              onOptionClick={this.maritalStatusClick}
              default={"Marital Status"}
              selected={this.state.maritalStatus.id}
              opts={this.state.maritalStatus}
              field_attr="id"
              value_attr="value"
            />
          </div>
        </div>
      );
      nationality = (
        <div className="col-md-3">
          <div className="form-group form-input">
            <input
              name="nationality"
              id="nationality"
              required
              value={this.state.fields["nationality"]}
            />
            <label for="nationality" className="form-label">
              Nationality
            </label>
          </div>
        </div>
      );
    }
    if (this.state.display1joint) {
      secondApplicant = (
        <div id="secondApplicant">
          <h4>Second Applicant</h4>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="name1"
                  id="name1"
                  MaxLength="25"
                  required
                  className={this.state.errors.name1 ? "error" : "success"}
                  value={this.state.fields["name1"]}
                  type="text"
                  ref="name1"
                  onBlur={this.handleName1KeyUp}
                  onChange={() => this.handleChange("name1")}
                />
                <label for="name1" className="form-label">
                  Name
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="email1"
                  id="email1"
                  MaxLength="25"
                  required
                  className={this.state.errors.email1 ? "error" : "success"}
                  value={this.state.fields["email1"]}
                  type="text"
                  ref="email1"
                  onBlur={this.handleEmail1KeyUp}
                  onChange={() => this.handleChange("email1")}
                />
                <label for="email1" className="form-label">
                  Email
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="mobile1"
                  id="mobile1"
                  MaxLength="10"
                  required //type="Number"
                  className={this.state.errors.mobile1 ? "error" : "success"}
                  value={this.state.fields["mobile1"]}
                  ref="mobile1"
                  onBlur={this.handleMobile1KeyUp}
                  onChange={() => this.handleChange("mobile1")}
                />
                <label for="mobile1" className="form-label">
                  Mobile
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="aadhaar1"
                  id="aadhaar1"
                  MaxLength="14"
                  required
                  type="text"
                  className={this.state.errors.aadhaar1 ? "error" : "success"}
                  value={this.state.fields["aadhaar1"]}
                  ref="aadhaar1"
                  onBlur={this.handleAadhaar1KeyUp}
                  onChange={() => this.handleChange("aadhaar1")}
                />
                <label for="aadhaar1" className="form-label">
                  Aadhaar
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="fatherSpouseName1"
                  id="fatherSpouseName1"
                  MaxLength="25"
                  required
                  className={
                    this.state.errors.fatherSpouseName1 ? "error" : "success"
                  }
                  value={this.state.fields["fatherSpouseName1"]}
                  type="text"
                  ref="fatherSpouseName1"
                  onBlur={this.handlefName1KeyUp}
                  onChange={() => this.handleChange("fatherSpouseName1")}
                />
                <label for="fatherSpouseName1" className="form-label">
                  Father/Spouse Name
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="ckyc1"
                  id="ckyc1"
                  required
                  type="number"
                  value={this.state.fields["ckyc1"]}
                  onChange={() => this.handleChange("ckyc1")}
                />
                <label for="ckyc1" className="form-label">
                  CKYC No
                </label>
              </div>
            </div>
            {guardian}
            {nationality}
            {gender}
            {maritalStatus}
          </div>

          <hr />
        </div>
      );
    }
    if (this.state.display2joint) {
      secondApplicant = (
        <div id="secondApplicant">
          <h4>Second Applicant</h4>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="name1"
                  id="name1"
                  MaxLength="25"
                  required
                  className={this.state.errors.name1 ? "error" : "success"}
                  value={this.state.fields["name1"]}
                  type="text"
                  ref="name1"
                  onBlur={this.handleName1KeyUp}
                  onChange={() => this.handleChange("name1")}
                />
                <label for="name1" className="form-label">
                  Name
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="email1"
                  id="email1"
                  MaxLength="25"
                  required
                  className={this.state.errors.email1 ? "error" : "success"}
                  value={this.state.fields["email1"]}
                  type="text"
                  ref="email1"
                  onBlur={this.handleEmail1KeyUp}
                  onChange={() => this.handleChange("email1")}
                />
                <label for="email1" className="form-label">
                  Email
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="mobile1"
                  id="mobile1"
                  MaxLength="10"
                  required //type="Number"
                  className={this.state.errors.mobile1 ? "error" : "success"}
                  value={this.state.fields["mobile1"]}
                  ref="mobile1"
                  onBlur={this.handleMobile1KeyUp}
                  onChange={() => this.handleChange("mobile1")}
                />
                <label for="mobile1" className="form-label">
                  Mobile
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="aadhaar1"
                  id="aadhaar1"
                  MaxLength="14"
                  required
                  type="text"
                  className={this.state.errors.aadhaar1 ? "error" : "success"}
                  value={this.state.fields["aadhaar1"]}
                  ref="aadhaar1"
                  onBlur={this.handleAadhaar1KeyUp}
                  onChange={() => this.handleChange("aadhaar1")}
                />
                <label for="aadhaar1" className="form-label">
                  Aadhaar
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="fatherSpouseName1"
                  id="fatherSpouseName1"
                  MaxLength="25"
                  required
                  className={
                    this.state.errors.fatherSpouseName1 ? "error" : "success"
                  }
                  value={this.state.fields["fatherSpouseName1"]}
                  type="text"
                  ref="fatherSpouseName1"
                  onBlur={this.handlefName1KeyUp}
                />
                <label for="fatherSpouseName1" className="form-label">
                  Father/Spouse Name
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="ckyc1"
                  id="ckyc1"
                  required
                  type="number"
                  value={this.state.fields["ckyc1"]}
                  onChange={() => this.handleChange("ckyc1")}
                />
                <label for="ckyc1" className="form-label">
                  CKYC No
                </label>
              </div>
            </div>
            {nationality}
            {gender}
          </div>
          <div className="row">{maritalStatus}</div> <hr />
        </div>
      );
      thirdApplicant = (
        <div id="thirdApplicant">
          <h4>Third Applicant</h4>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="name2"
                  id="name2"
                  MaxLength="25"
                  required
                  className={this.state.errors.name2 ? "error" : "success"}
                  value={this.state.fields["name2"]}
                  type="text"
                  ref="name2"
                  onBlur={this.handleName2KeyUp}
                  onChange={() => this.handleChange("name2")}
                />
                <label for="name2" className="form-label">
                  Name
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="email2"
                  id="email2"
                  MaxLength="25"
                  required
                  className={this.state.errors.email2 ? "error" : "success"}
                  value={this.state.fields["email2"]}
                  type="text"
                  ref="email2"
                  onBlur={this.handleEmail2KeyUp}
                  onChange={() => this.handleChange("email2")}
                />
                <label for="email2" className="form-label">
                  Email
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="mobile2"
                  id="mobile1"
                  MaxLength="10"
                  required //type="Number"
                  className={this.state.errors.mobile2 ? "error" : "success"}
                  value={this.state.fields["mobile2"]}
                  ref="mobile2"
                  onBlur={this.handleMobile2KeyUp}
                  onChange={() => this.handleChange("mobile2")}
                />
                <label for="mobile2" className="form-label">
                  Mobile
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="aadhaar2"
                  id="aadhaar2"
                  MaxLength="14"
                  required
                  type="text"
                  className={this.state.errors.aadhaar2 ? "error" : "success"}
                  value={this.state.fields["aadhaar2"]}
                  ref="aadhaar2"
                  onBlur={this.handleAadhaar2KeyUp}
                  onChange={() => this.handleChange("aadhaar2")}
                />
                <label for="aadhaar2" className="form-label">
                  Aadhaar
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="fatherSpouseName2"
                  id="fatherSpouseName2"
                  MaxLength="25"
                  required
                  className={
                    this.state.errors.fatherSpouseName2 ? "error" : "success"
                  }
                  value={this.state.fields["fatherSpouseName2"]}
                  type="text"
                  ref="fatherSpouseName2"
                  onBlur={this.handlefName2KeyUp}
                  onChange={() => this.handleChange("fatherSpouseName2")}
                />
                <label for="fatherSpouseName2" className="form-label">
                  Father/Spouse Name
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group form-input">
                <input
                  name="ckyc2"
                  id="ckyc2"
                  required
                  type="number"
                  value={this.state.fields["ckyc2"]}
                  onChange={() => this.handleChange("ckyc2")}
                />
                <label for="ckyc2" className="form-label">
                  CKYC No
                </label>
              </div>
            </div>
            {nationality}
            {gender}
          </div>
          <div className="row">{maritalStatus}</div> <hr />
        </div>
      );
    }
    return (
      <Fragment>
        <Header userRole="user" />
        <div className="col-md-12 col-offset-6 bg-lighter px-4 pt-2 padding-top-bottom">
          <form
            className="col-md-12 needs-validation"
            onSubmit={this.handleSubmit}
          >
            <div className="row">
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="name"
                    id="name"
                    MaxLength="25"
                    required
                    className={this.state.errors.name ? "error" : "success"}
                    value={this.state.fields["name"]}
                    type="text"
                    ref="name"
                    onBlur={this.handleNameKeyUp}
                    onChange={() => this.handleChange("name")}
                  />
                  <label for="name" className="form-label">
                    Name
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="email"
                    id="email"
                    MaxLength="25"
                    required
                    className={this.state.errors.email ? "error" : "success"}
                    value={this.state.fields["email"]}
                    type="text"
                    ref="email"
                    onBlur={this.handleEmailKeyUp}
                    onChange={() => this.handleChange("email")}
                  />
                  <label for="email" className="form-label">
                    Email
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="mobile"
                    id="mobile"
                    MaxLength="10"
                    required //type="Number"
                    className={this.state.errors.mobile ? "error" : "success"}
                    value={this.state.fields["mobile"]}
                    ref="mobile"
                    onBlur={this.handleMobileKeyUp}
                    onChange={() => this.handleChange("mobile")}
                  />
                  <label for="mobile" className="form-label">
                    Mobile
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="aadhaar"
                    id="aadhaar"
                    MaxLength="14"
                    required
                    type="text"
                    className={this.state.errors.aadhaar ? "error" : "success"}
                    value={this.state.fields["aadhaar"]}
                    ref="aadhaar"
                    onBlur={this.handleAadhaarKeyUp}
                    onChange={() => this.handleChange("aadhaar")}
                  />
                  <label for="aadhaar" className="form-label">
                    Aadhaar
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="fatherSpouseName"
                    id="fatherSpouseName"
                    MaxLength="25"
                    required
                    className={
                      this.state.errors.fatherSpouseName ? "error" : "success"
                    }
                    value={this.state.fields["fatherSpouseName"]}
                    type="text"
                    ref="name"
                    onBlur={this.handlefNameKeyUp}
                    onChange={() => this.handleChange("fatherSpouseName")}
                  />
                  <label for="fatherSpouseName" className="form-label">
                    Father/Spouse Name
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group form-input">
                  <input
                    name="ckyc"
                    id="ckyc"
                    required
                    type="number"
                    value={this.state.fields["ckyc"]}
                    onChange={() => this.handleChange("ckyc")}
                  />
                  <label for="ckyc" className="form-label">
                    CKYC No
                  </label>
                </div>
              </div>
              {guardian}

              {nationality}

              {gender}
              {maritalStatus}
            </div>

            <hr />
            {secondApplicant}
            {thirdApplicant}

            <div className="form-submit">
              <input
                type="submit"
                value="Next"
                className="btn btn-md mx-1 btn-warning px-5 button-width right"
                id="submit"
                name="submit"
              />
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default ClientInfo;
