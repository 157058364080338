import React, { Fragment, Component } from "react";
import "./Dropdown.scss";

class DropdownButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectClass: ""
    };
  }
  handleOnChange = e => {
    this.props.onOptionClick(e);
    this.setState({ SelectClass: "select-wrap" });
  };

  render() {
    let labelText = this.props.default;

    let selectedOpt = null;
    for (let opt of this.props.opts)
      if (this.props.selected === opt[this.props.field_attr] || opt.default) {
        selectedOpt = opt;
      }

    if (selectedOpt)
      labelText = this.props.value_attr
        .split(",")
        .map((v, i) => (i > 0 ? ", " : "") + selectedOpt[v]);

    return (
      <Fragment>
        <div class="form-group">
          <div class="select-list">
            <select
              name={this.props.name}
              id={this.props.id}
              className={"color-placeholder " + this.state.SelectClass}
              onChange={this.handleOnChange.bind(this)}
              data-id={this.props.dataid}
              value={this.props.selected}
              required
            >
              <option value="" disabled selected>
                {labelText}
              </option>
              {this.props.opts.map(opt => (
                <option
                  className={
                    this.props.selected === opt[this.props.field_attr]
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  value={opt[this.props.field_attr]}
                >
                  {opt[this.props.value_attr]}
                </option>
              ))}
            </select>
            <label className="label-for-check">{labelText}</label>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DropdownButton;
