import React, { Component, Fragment } from "react";
import Header from '../../components/Header/Header';
import TransactionTabs from './TransactionTabs';

import Purchase from './Purchase';
import Redemption from './Redemption';
import SwitchScheme from './SwitchScheme';
import Systematic from './Systematic';
import Mandate from './Mandate';
import Cart from './Cart';
import History from './History';

class RecatTemplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'purchase'
    };
  }

  handleTabActive = (activeTab) => {
    this.setState({activeTab});
  }

  render() {
    const { activeTab } = this.state;
    return (
      <Fragment>
        <Header userRole="user" />
        <div className="container app_layout pt-2">
          <div className="mb-5">
            <TransactionTabs active={activeTab} handleTabActive={this.handleTabActive} />
          </div>
        </div>
        {
          activeTab === 'purchase' &&
            <Purchase />
        }
        {
          activeTab === 'redemption' &&
            <Redemption />
        }
        {
          activeTab === 'switch' &&
            <SwitchScheme />
        }
        {
          activeTab === 'systematic' &&
            <Systematic />
        }
        {
          activeTab === 'e-mandate' &&
            <Mandate />
        }
        {
          activeTab === 'cart' &&
            <Cart />
        }
        {
          activeTab === 'history' &&
            <History />
        }

      </Fragment>
    )
  }
};

export default RecatTemplete;
