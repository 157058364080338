import React, { Component, Fragment } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import config from '../../config';
import { getAuthUser, removeAuthUser } from '../../utils/sessionManager';
// import modals
import NewAccountModal from '../../containers/Account/NewAccountModal';

import './Header.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      showNewAccountModal: false,
    };
  }

  componentWillMount() {
    if(getAuthUser('user')){
      this.setState({isLogged:true});
    }
  }

  logOut = (e) => {
    removeAuthUser('user');
    this.setState({isLogged:false});
    this.props.history.push('/login');
  }

  handleNewAccountModal = (e) => {
    e.preventDefault();
    this.setState({ showNewAccountModal: true });
  }

  render() {
    const { isLogged } = this.state;
    const { userRole } = this.props;
    return (
      <Fragment>
        <div className="al_navbar">
          <Navbar collapseOnSelect bg="light" variant="light" expand="lg" fixed="top" className="bg-dark">
            <Container fluid={false}>
              <div className="navbar-brand">
                <Link to="/">
                  <img
                    src={config.s3_url + '/favicon.png'}
                    alt="logo"
                    className="nav_logo"
                  />
                  <div className="nav_logo_text">Alchemy</div>
                </Link>
              </div>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />

              <Navbar.Collapse id="responsive-navbar-nav">
                {/* dynamic navbar  */}
                {
                  userRole &&
                  <Fragment>
                    <Nav className="nav_item">
                      <NavLink to="/dashboard" activeClassName="active">
                        Dashboard
                      </NavLink>
                    </Nav>
                    <Nav className="nav_item">
                      <NavLink to="/transaction" activeClassName="active">
                        Transaction
                      </NavLink>
                    </Nav>
                    <Nav className="nav_item">
                      <NavLink to="/report" activeClassName="active">
                        Report
                      </NavLink>
                    </Nav>
                    <Nav className="nav_item">
                      <NavLink to="/aof" activeClassName="active">
                        Account Creation
                      </NavLink>
                    </Nav>
                   {/* <NavDropdown title="Account Creation" id="nav-dropdown-account-creation" className="nav_dropdown_item">
                      <Link
                        to="#"
                        className="dropdown-item"
                        onClick={this.handleNewAccountModal}
                      >
                        New
                      </Link>
                      <Link to="#" className="dropdown-item">
                        RTA
                      </Link>
                      <Link to="#" className="dropdown-item">
                        CRM
                      </Link>
                    </NavDropdown> */}

                    {/* load modals here */}
                    <NewAccountModal
                      show={this.state.showNewAccountModal}
                      onHide={()=> this.setState({ showNewAccountModal: false })}
                    />
                  </Fragment>
                }

                {/* supports */}
                {
                  userRole &&
                  <NavDropdown title="Support" id="nav-dropdown-support" className="nav_dropdown_item">
                    <NavDropdown.Item href="mailto:support@pulselabs.co.in">
                      Email: support@pulselabs.co.in
                    </NavDropdown.Item>
                    <NavDropdown.Item href="tel:987-654-3210">
                      Phone: 987-654-3210
                    </NavDropdown.Item>
                  </NavDropdown>
                }

                {/* logged out */}
                <Nav className="mr-auto">
                </Nav>
                {
                  isLogged && <Nav className="al_login_text" onClick={this.logOut}>Logout</Nav>
                }
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Header);
