import React, { Component, Fragment } from "react";
import Button from '../../components/Button/Button';
import Select from 'react-select';
import {
  MUTUAL_FUNDS,
  ADDITIONAL_FUNDS
} from '../../constants/constants';

// import actions
import { getConsolidated } from '../../actions/transactionActions';

const client = {
  "advisor_id": "ARN-110384",
  "client_id": "1081227",
  "sub_advisor_id": ""
};
class Systematic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActiveSIPs: 'sip',
      isActive: 'fresh',
      funds: [],
      mutualFunds: MUTUAL_FUNDS,
      additionalFundList: ADDITIONAL_FUNDS,
      selectedScheme: null,
      isMoreFilter: false,
      fetching: true,
      server_error: '',
    };
  }

  getInitialData = async () => {
    try {
      const firstResponse = await getConsolidated(client);
      const consolidate = firstResponse.data;
      consolidate.data.forEach(fund => {
        fund.isAmount = true;
      });
      this.setState({
        funds: consolidate.data,
        fetching: false,
      });

    } catch (err) {
      console.error('getInitialData, Server err: ', err.response);
      const data = err.response.data;
      this.setState({ server_error: data.error });
    }
  }

  componentDidMount() {
    this.getInitialData();
  }

  handleChangeScheme = (selectedScheme) => {
    this.setState({ selectedScheme });
    console.log(`Option selected:`, selectedScheme);
  }

  handleAmount = (index) => {
    const funds = this.state.funds;
    const fund = funds[index];
    fund.isAmount = !fund.isAmount;
    this.setState({ funds });
  }

  render() {
    const {
      isActiveSIPs,
      isActive,
      funds,
      mutualFunds,
      additionalFundList,
      selectedScheme,
      isMoreFilter,
      fetching,
    } = this.state;
    return (
      <Fragment>

        <div id="systematic">

          <div className="container">
            <div className="row mb-3 mt-4">
              <div className="col">
                <div className="al_scheme_tabs_1 w-100">
                  <ul className="nav nav-pills nav-justified">
                    <li className="nav-item" onClick={() => this.setState({ isActiveSIPs: 'sip' })}>
                      <span className={`nav-link ${isActiveSIPs === 'sip' ? 'active' : ''}`}>
                        SIP
                    </span>
                    </li>
                    <li className="nav-item" onClick={() => this.setState({ isActiveSIPs: 'swp' })}>
                      <span className={`nav-link ${isActiveSIPs === 'swp' ? 'active' : ''}`}>
                        SWP
                    </span>
                    </li>
                    <li className="nav-item" onClick={() => this.setState({ isActiveSIPs: 'stp' })}>
                      <span className={`nav-link ${isActiveSIPs === 'stp' ? 'active' : ''}`}>
                        STP
                    </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {
            isActiveSIPs === 'sip' &&
            <div className="container">
              <div className="row">
                <div className="al_scheme_tabs">
                  <ul className="nav nav-fill">
                    <li className="nav-item" onClick={() => this.setState({ isActive: 'fresh' })}>
                      <span className={`nav-link ${isActive === 'fresh' ? 'active' : ''}`}>
                        Fresh
                        </span>
                    </li>
                    <li className="nav-item" onClick={() => this.setState({ isActive: 'additonal' })}>
                      <span className={`nav-link ${isActive === 'additonal' ? 'active' : ''}`}>
                        Additonal
                        </span>
                    </li>
                  </ul>
                </div>
              </div>

              {
                isActive === 'fresh' &&
                <div>
                  <div className="row mt-3">
                    <div className="col-md-9">
                      <div className="form-group">
                        <label htmlFor="scheme">Scheme:</label>
                        <Select
                          className="al_scheme_select"
                          name="scheme"
                          isClearable={true}
                          isSearchable={true}
                          placeholder="Select scheme..."
                          value={selectedScheme}
                          onChange={this.handleChangeScheme}
                          options={mutualFunds}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <span className="cursor" onClick={() => this.setState({ isMoreFilter: !this.state.isMoreFilter })}>
                      {
                        isMoreFilter ?
                          <i className="fa fa-minus-circle" aria-hidden="true"></i>
                          : <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      }
                      <span className="ml-1">More Filters</span>
                    </span>
                  </div>

                  {
                    isMoreFilter &&
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group has-search">
                          <label htmlFor="amc">Amc:</label>
                          <i className="fa fa-search form-control-search"></i>
                          <input type="text" className="form-control" id="amc" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group has-search">
                          <label htmlFor="asset_class">Asset Class:</label>
                          <i className="fa fa-search form-control-search"></i>
                          <input type="text" className="form-control" id="asset_class" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group has-search">
                          <label htmlFor="amc_scheme">Scheme:</label>
                          <i className="fa fa-search form-control-search"></i>
                          <input type="text" className="form-control" id="amc_scheme" />
                        </div>
                      </div>
                    </div>
                  }


                  <div className="row justify-content-center mt-3">
                    <div className="form-group form-inline mb-0">
                      <label htmlFor="amount">Amount:</label>
                      <div className="input-group ml-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text al_amount">Rs</div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="amount"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center mt-3">
                    <div className="mr-4">
                      <Button type="button" text="Book Another SIP" />
                    </div>
                    <Button type="button" text="Transact Now" />
                  </div>

                </div>

              }

              {
                isActive === 'additonal' &&
                <div>
                  <div className="table-responsive mt-4">
                    <table className="table table-hover al_table">
                      <thead>
                        <tr>
                          <th scope="col">Scheme</th>
                          <th scope="col">Folio No</th>
                          <th scope="col">Units</th>
                          <th scope="col">Amount</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          additionalFundList.map((fund, index) => {
                            return (
                              <tr key={index}>
                                <td>{fund.scheme}</td>
                                <td>{fund.folioNo}</td>
                                <td>{fund.units}</td>
                                <td>{fund.amount}</td>
                                <td>
                                  <div style={{ width: '150px', display: 'flex' }}>
                                    <span style={{ height: '27px' }}>
                                      <Button
                                        type="button"
                                        text="Buy"
                                        size="sm"
                                      // onClick={() => this.handleSelectedFund(index)}
                                      />
                                    </span>
                                    {/* <div style={{display: selectedIndex == index ? '' : 'none'}}> */}
                                    <input
                                      style={{ width: '130px', height: '32px', marginLeft: '10px' }}
                                      type="text"
                                      className="form-control"
                                      name="amount"
                                      placeholder="Amount"
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>

                  <div className="row justify-content-center mt-3">
                    <div className="mr-4">
                      <Button type="button" text="Buy Another Fund" />
                    </div>
                    <Button type="button" text="Transact Now" />
                  </div>
                </div>
              }

            </div>
          }

          {
            isActiveSIPs === 'swp' &&
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-hover al_table">
                      <thead>
                        <tr>
                          <th scope="col">Scheme</th>
                          <th scope="col">Folio No</th>
                          <th scope="col">Units</th>
                          <th scope="col">Current Value</th>
                          <th scope="col"><strong>Systematic Withdrawal</strong></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fetching && (
                          <tr>
                            <td colSpan="20" className="text-center">loading...</td>
                          </tr>
                        )}
                        {!fetching && funds.length > 0 && (
                          funds.map((fund, index) => {
                            return (
                              <tr key={index}>
                                <td>{fund.scheme_name}</td>
                                <td>{fund.scheme_folio_no}</td>
                                <td>{fund.unit_balance}</td>
                                <td>{fund.current_value}</td>
                                <td>
                                  <div className="row no-gutters">
                                    <div className="form-check mr-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={'redemAmount' + index}
                                        id={'amount' + index}
                                        checked={fund.isAmount}
                                        onChange={() => this.handleAmount(index)}
                                      />
                                      <label className="form-check-label" htmlFor={'amount' + index} style={{ marginTop: '3px' }}>
                                        By Amount
                                    </label>
                                    </div>
                                    <div className="form-check mr-4">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={'redemAmount' + index}
                                        id={'units' + index}
                                        checked={!fund.isAmount}
                                        onChange={() => this.handleAmount(index)}
                                      />
                                      <label className="form-check-label" htmlFor={'units' + index} style={{ marginTop: '3px' }}>
                                        By Units
                                    </label>
                                    </div>
                                    <div className="table_input">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="amount"
                                        placeholder={fund.isAmount ? 'Amount' : 'Units'}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <div className="mr-4">
                  <Button type="button" text="Transact Now" />
                </div>
                <Button type="button" text="Add to Cart" />
              </div>
            </div>
          }

          {
            isActiveSIPs === 'stp' &&
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-hover al_table">
                      <thead>
                        <tr>
                          <th scope="col">Scheme</th>
                          <th scope="col">Folio No</th>
                          <th scope="col">Units</th>
                          <th scope="col">Current Value</th>
                          <th scope="col"><strong>Systematic Transfer</strong></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fetching && (
                          <tr>
                            <td colSpan="20" className="text-center">loading...</td>
                          </tr>
                        )}
                        {!fetching && funds.length > 0 && (
                          funds.map((fund, index) => {
                            return (
                              <tr key={index}>
                                <td>{fund.scheme_name}</td>
                                <td>{fund.scheme_folio_no}</td>
                                <td>{fund.unit_balance}</td>
                                <td>{fund.current_value}</td>
                                <td>
                                  <div className="row no-gutters">
                                    <div className="form-check mr-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={'redemAmount' + index}
                                        id={'amount' + index}
                                        checked={fund.isAmount}
                                        onChange={() => this.handleAmount(index)}
                                      />
                                      <label className="form-check-label" htmlFor={'amount' + index} style={{ marginTop: '3px' }}>
                                        By Amount
                                    </label>
                                    </div>
                                    <div className="form-check mr-4">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={'redemAmount' + index}
                                        id={'units' + index}
                                        checked={!fund.isAmount}
                                        onChange={() => this.handleAmount(index)}
                                      />
                                      <label className="form-check-label" htmlFor={'units' + index} style={{ marginTop: '3px' }}>
                                        By Units
                                    </label>
                                    </div>
                                    <div className="table_input">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="amount"
                                        placeholder={fund.isAmount ? 'Amount' : 'Units'}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <div className="mr-4">
                  <Button type="button" text="Transact Now" />
                </div>
                <Button type="button" text="Add to Cart" />
              </div>

            </div>
          }

        </div>
      </Fragment>
    )
  }
};

export default Systematic;
