import React, { Fragment, Component } from "react";
import Header from "../../components/Header/Header";
import KYCSearch from "./KYCSearch";
import AOF from "./AccountOpeningForm";
// import modals
import NewAccountModal from "./InvestorSelectionModal";
import RTAAccountModal from "./InvestorSelectionModal";
import RTADataSelection from "./RTAMultiClientDataSelecter";

class NewAccountCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "",
      newActive: "",
      rtaActive: "",
      crmActive: "",
      showNewAccountModal: false,
      newAccountDisplay: false,
      accountTypeData: {},
      showNewKYCSearch: false,
      showNewAccountForm: false,
      NewAccountData: {},
      kycVerification: {},
      showRTAAccountModal: false,
      rtaAccountDisplay: false,
      showRTAKYCSearch: false,
      showRTAAccountForm: false,
      RTAAccountData: {},
      showRTADataSelectionModal: false,
      RTAMultipleData: []
    };
  }

  handleNewAccountModal = () => {
    this.setState({ showNewAccountModal: true , accountTypeData: {}, newAccountDisplay: false });
  };

  handleNewSubmitButton = (e, data) => {
    e.preventDefault();
    this.setState({ showNewAccountModal: false , showNewKYCSearch: true , accountTypeData: data , newAccountDisplay: true });
  };

  handleNewAccountSubmit = (e, data) => {
    e.preventDefault();
    this.setState({ showNewKYCSearch: false , kycVerification: data , showNewAccountForm: true , NewAccountData: "" });
  };

  handleRTAAccountModal = () => {
    this.setState({ showRTAAccountModal: true , accountTypeData: {} , rtaAccountDisplay: false });
  };

  handleRTASubmitButton = (e, data) => {
    e.preventDefault();
    this.setState({ showRTAAccountModal: false , showRTAKYCSearch: true, accountTypeData: data, rtaAccountDisplay: true });
  };

  handleRTAAccountSubmit = (e, data) => {
    e.preventDefault();
    this.setState({ showRTAKYCSearch: true , RTAMultipleData: data , showRTADataSelectionModal: true });
  };

  handleDataSelectionSubmit = (e, data) => {
    e.preventDefault();
    this.setState({ showRTADataSelectionModal: false , showRTAAccountForm: true , showRTAKYCSearch: false , RTAAccountData: data });
  };

  handleModal = type => {
    this.setState({ display: type }, function() {
      switch (this.state.display) {
        case "New":
          this.setState({ newActive: "active" , rtaActive: "" , crmActive: "" });
          this.handleNewAccountModal();
          break;
        case "RTA":
          this.setState({ newActive: "" , rtaActive: "active" , crmActive: "" });
          this.handleRTAAccountModal();
          break;
        case "CRM":
          this.setState({ newActive: "" , rtaActive: "" , crmActive: "active" });
          break;
        default:
          this.setState({ newActive: "", rtaActive: "" , crmActive: "" });
          break;
      }
    });
  };

  render() {
    const h = window.innerHeight;

    let newAccount,
      newKYCSearch,
      newAccountForm,
      rtaAccount,
      rtaKYCSearch,
      rtaAccountForm,
      rtaModal;

    if (this.state.newAccountDisplay) {
      if (this.state.showNewKYCSearch) {
        newKYCSearch = (
          <KYCSearch
            accountTypeData={this.state.accountTypeData}
            handleAccountSubmit={this.handleNewAccountSubmit}
          />
        );
      } else if (this.state.showNewAccountForm) {
        newAccountForm = (
          <AOF
            ClientData={this.state.NewAccountData}
            accountTypeData={this.state.accountTypeData}
            kycVerification={this.state.kycVerification}
          />
        );
      }
      newAccount = (
        <div>
          {newKYCSearch}
          {newAccountForm}
        </div>
      );
    }

    if (this.state.rtaAccountDisplay) {
      if (this.state.showRTAKYCSearch) {
        rtaKYCSearch = (
          <KYCSearch
            accountTypeData={this.state.accountTypeData}
            handleAccountSubmit={this.handleRTAAccountSubmit}
          />
        );
        if (this.state.showRTADataSelectionModal) {
          rtaModal = (
            <RTADataSelection
              show={this.state.showRTADataSelectionModal}
              onHide={() => this.setState({ showRTADataSelectionModal: false })}
              handleSubmit={this.handleDataSelectionSubmit}
              modal={this.state.RTAMultipleData}
            />
          );
        }
      } else if (this.state.showRTAAccountForm) {
        rtaAccountForm = (
          <AOF
            ClientData={this.state.RTAAccountData}
            accountTypeData={this.state.accountTypeData}
            kycVerification={this.state.kycVerification}
          />
        );
      }
      rtaAccount = (
        <div>
          {rtaKYCSearch}
          {rtaModal}
          {rtaAccountForm}
        </div>
      );
    }

    let accountType = "";
    if (!this.state.display) {
      accountType = <div>Select Account Type</div>;
    } else if (this.state.display === "New") {
      accountType = newAccount;
    } else if (this.state.display === "RTA") {
      accountType = rtaAccount;
    } else if (this.state.display === "CRM") {
    }

    return (
      <Fragment>
        <Header userRole="user" />
        <div
          id="accountCreation"
          className="container-fluid"
          style={{
            marginTop: h / 2 - (205 + 150),
            height: h
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <ul class="nav nav-pills">
                <li class={"new " + this.state.newActive}>
                  <button
                    onClick={() => this.handleModal("New")}
                    class="btn_link"
                  >
                    New <i class="fa fa-bar-chart icon fa-1x" />
                  </button>
                </li>
                <li class={"rta " + this.state.rtaActive}>
                  <button
                    onClick={() => this.handleModal("RTA")}
                    class="btn_link"
                  >
                    RTA <i class="fa fa-bar-chart icon fa-1x" />
                  </button>
                </li>
                <li class={"crm " + this.state.crmActive}>
                  <button
                    onClick={() => this.handleModal("CRM")}
                    class="btn_link"
                  >
                    CRM <i class="fa fa-university icon fa-1x" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="rows">
            <div className="col-md-12">
              <h2>{this.state.display}</h2>
              {accountType}
            </div>
          </div>
        </div>
        <NewAccountModal
          show={this.state.showNewAccountModal}
          onHide={() => this.setState({ showNewAccountModal: false })}
          handleSubmit={this.handleNewSubmitButton}
          isRender={true}
          title="New"
        />
        <RTAAccountModal
          show={this.state.showRTAAccountModal}
          onHide={() => this.setState({ showRTAAccountModal: false })}
          handleSubmit={this.handleRTASubmitButton}
          title="RTA"
        />
      </Fragment>
    );
  }
}

export default NewAccountCreation;
