import config from '../config';
import {apiCalls} from './apiCalls';

export const transactNow = (scheme) => {
  const url = config.base_url + `/mf-order/mfrder/order-entry`;

  const reqObject = {
    "TransCode": "NEW",
    "TransNo": "20190221",
    "OrderId": null,
    "UserID": "MANISH1",
    "MemberId": "PULSLAB1",
    "ClientCode": "manish1234",
    "SchemeCd": scheme.value,
    "BuySell": "P",
    "BuySellType": "FRESH",
    "DPTxn": "P",
    "OrderVal": parseInt(scheme.amount),
    "Qty": null,
    "AllRedeem": "Y",
    "FolioNo": null,
    "Remarks": null,
    "KYCStatus": "Y",
    "RefNo": "Hazarath",
    "SubBrCode": null,
    "EUIN": "E084436",
    "EUINVal": "N",
    "MinRedeem": "N",
    "DPC": "Y",
    "IPAdd": null,
    "Password": "@123456",
    "PassKey": "Pulselab",
    "Parma1": null,
    "Param2": null,
    "Param3": null
  };

  return apiCalls('post', url, reqObject);
}

export const getConsolidated = (client) => {
  const url = config.base_url + `/rta_report/client_portfolio_consolidated`;
  const reqObject = {
    "advisor_id": client.advisor_id,
    "client_id": client.client_id,
    "sub_advisor_id": client.sub_advisor_id
  };
  return apiCalls('post', url, reqObject);
}

export const getLevelGains = (client) => {
  const url = config.base_url + `/rta_report/client_portfolio_transaction_level_gains`;
  const reqObject = {
    "advisor_id": client.advisor_id,
    "client_id": client.client_id,
    "sub_advisor_id": client.sub_advisor_id
  };
  return apiCalls('post', url, reqObject);
}
