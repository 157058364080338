import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
// import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/scss/app.scss';

//ag-grid design
import "ag-grid/dist/styles/ag-grid.css";
import "ag-grid/dist/styles/ag-theme-material.css";

import "react-datepicker/dist/react-datepicker.css";

import Routes from './routes/Routes';

const AppBundle = (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);

ReactDOM.render(AppBundle, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
