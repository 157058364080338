import React, { Component, Fragment } from "react";
import Header from '../../components/Header/Header';
import { getAuthUser } from '../../utils/sessionManager';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
    };
  }

  componentWillMount() {
    if (getAuthUser('user')) {
      this.setState({ isLogged: true });
    }
  }

  render() {
    const { isLogged } = this.state;
    return (
      <Fragment>
        <Header />
        <div className="text-center" id="home">
          <div className="container mt-5">
            <div className="row text-center">
              <div className="col-md-12">
                <h2 className="heading_text">Alchemy</h2>
                {isLogged ?
                  (<button
                    type="button"
                    className="btn btn-md mx-1 btn-warning px-5"
                    onClick={() => this.props.history.push('/dashboard')}
                  >
                    Dashboard
                  </button>)
                  :
                  (<button
                    type="button"
                    className="btn btn-md mx-1 btn-warning px-5"
                    onClick={() => this.props.history.push('/login')}
                  >
                    Sign In
                  </button>)
                }

              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
};

export default Home;
