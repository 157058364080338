import React, { Fragment, Component } from "react";
import Header from "../../components/Header/Header";
import ClientInfo from "./ClientInfo";
import Address from "./Address";
import FactaInfo from "./FactaInfo";
import Nomination from "./Nomination";
import BankDetails from "./BankDetails";

class AccountOoeningForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressTabClass: "",
      addressActive: false,
      addressTabShow: "",
      clientTabClass: "active",
      clientActive: true,
      clientTabShow: " show active",
      bankTabClass: "",
      bankActive: false,
      factaTabClass: "",
      factaActive: false,
      nominationTabClass: "",
      nominationActive: false,
      kycVerification: this.props.kycVerification,
      accountTypeData: this.props.accountTypeData,
      fields: this.props.ClientData === "" ? {} : this.props.ClientData
    };
  }

  //client info next
  clientInfoNextHandler = () => {
    this.setState({ addressTabClass: "active" , addressActive: true , clientTabClass: "" ,
     clientActive: false , clientTabShow: "" , addressTabShow: " show active" });
  }

  render() {
    return (
      <Fragment>
        <Header userRole="user" />

        <div id="AccountCreationForm" className="container-fluid">
          <div className="tabWidth">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item nav-item-fixed-width">
                <a
                  className={"nav-link " + this.state.clientTabClass}
                  id="clientInfo-tab"
                  data-toggle="tab"
                  href="#clientInfo"
                  role="tab"
                  aria-controls="clientInfo"
                  aria-selected={this.state.clientActive}
                >
                  <i class="fa fa-user icon fa-2x" />
                  <br />
                  Client Information
                </a>
              </li>
              <li className="nav-item nav-item-fixed-width">
                <a
                  className={"nav-link " + this.state.addressTabClass}
                  id="address-tab"
                  data-toggle="tab"
                  href="#address"
                  role="tab"
                  aria-controls="address"
                  aria-selected={this.state.addressActive}
                >
                  <i class="fa fa-address-card icon fa-2x" />
                  <br />
                  Address
                </a>
              </li>
              <li className="nav-item nav-item-fixed-width">
                <a
                  className="nav-link"
                  id="bank-tab"
                  data-toggle="tab"
                  href="#bank"
                  role="tab"
                  aria-controls="bank"
                  aria-selected="false"
                >
                  <i class="fa fa-bank icon fa-2x" />
                  <br />
                  Bank Details
                </a>
              </li>
              <li className="nav-item nav-item-fixed-width">
                <a
                  className="nav-link"
                  id="fatca-tab"
                  data-toggle="tab"
                  href="#fatca"
                  role="tab"
                  aria-controls="fatca"
                  aria-selected="false"
                >
                  <i class="fa fa-info-circle icon fa-2x" />
                  <br />
                  FATCA Details
                </a>
              </li>
              <li className="nav-item nav-item-fixed-width">
                <a
                  className="nav-link"
                  id="nomination-tab"
                  data-toggle="tab"
                  href="#nomination"
                  role="tab"
                  aria-controls="nomination"
                  aria-selected="false"
                >
                  <i class="fa fa-users icon fa-2x" />
                  <br />
                  Nomination Details
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className={"tab-pane fade" + this.state.clientTabShow}
                id="clientInfo"
                role="tabpanel"
                aria-labelledby="clientInfo-tab"
              >
                <ClientInfo
                  action={this.clientInfoNextHandler}
                  data={this.state.fields}
                  accountTypeData={this.state.accountTypeData}
                  kycVerification={this.state.kycVerification}
                />
              </div>
              <div
                className={"tab-pane fade" + this.state.addressTabShow}
                id="address"
                role="tabpanel"
                aria-labelledby="address-tab"
              >
                <Address
                  nextTab="bank-tab"
                  data={this.state.fields}
                  accountTypeData={this.state.accountTypeData}
                  kycVerification={this.state.kycVerification}
                />
              </div>
              <div
                className="tab-pane fade"
                id="bank"
                role="tabpanel"
                aria-labelledby="bank-tab"
              >
                <BankDetails
                  nextTab="fatca-tab"
                  data={this.state.fields}
                  accountTypeData={this.state.accountTypeData}
                  kycVerification={this.state.kycVerification}
                />
              </div>
              <div
                className="tab-pane fade"
                id="fatca"
                role="tabpanel"
                aria-labelledby="fatca-tab"
              >
                <FactaInfo
                  nextTab="nomination-tab"
                  data={this.state.fields}
                  accountTypeData={this.state.accountTypeData}
                  kycVerification={this.state.kycVerification}
                />
              </div>
              <div
                className="tab-pane fade"
                id="nomination"
                role="tabpanel"
                aria-labelledby="nomination-tab"
              >
                <Nomination
                  data={this.state.fields}
                  accountTypeData={this.state.accountTypeData}
                  kycVerification={this.state.kycVerification}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AccountOoeningForm;
