import React, { Fragment, Component } from "react";
import DataGrid from "../../components/DataGrid/DataGrid";
// import actions
import { processingLoad } from "../../actions/reportsAction";

class ProcessingStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      advisor: "ARN-110384",
      rowData: [],
      columnDefs: [
        { headerName: "RTA", field: "vendor_name" },
        {
          headerName: "Date of",
          children: [
            { headerName: "First Transaction", field: "first_transaction" },
            { headerName: "Last Transaction", field: "last_transaction" }
          ]
        },
        { headerName: "Report Generation", field: "report_aum_date" }
      ]
    };
    // Bind the this context to the handler function
  }

  componentDidMount = () => {
    this.handleProcessingLoad();
  };

    onRowSelected(event) {}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  handleProcessingLoad = async () => {
    const err = "";
    if (!err) {
      const data = this.state.advisor;
      try {
        const clientDataRes = await processingLoad(data);
        const res = clientDataRes.data;
        if (res.status === "success" && res.data !== undefined) {
          this.setState({ rowData: res.data, fetching: true });
        }
      } catch (err) {
        console.error("handleProcessingLoad, Server err: ", err.response);
      }
    }
  };

  render() {
    const { fetching, rowData } = this.state;
    return (
      <Fragment>
        <div id="processing">
          {!fetching && <div className="text-center">loading...</div>}
          {fetching && rowData.length > 0 && (
            <DataGrid
              ref="idreviewgrid"
              RowDef={this.state.rowData}
              onRowSelected={this.onRowSelected.bind(this)}
              onGridReady={this.onGridReady.bind(this)}
              ColumnDef={this.state.columnDefs}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default ProcessingStats;
