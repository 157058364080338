import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';


import Page404 from './Page404';
import PrivateRoute from './PrivateRoute';

// Home components
import Home from '../containers/Home/Home';

// Auth components
import Login from '../containers/Login/Login';

// Dashboard components
import Dashboard from '../containers/Dashboard/Dashboard';

// transaction components

import AOF from "../containers/AccountCreation/AccountCreation";
import Report from "../containers/RTAStats/Report";

import Transaction from "../containers/Transaction/Transaction";


const Root = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        {/* <Route exact path="/dashboard" component={Dashboard} /> */}
        {/* <Route exact path="/kyc-search" component={KYCSearch} />
        <Route exact path="/aof" component={AOF} exact />         */}

        {/* transaction routes */}
        <Route path="/aof" component={AOF} exact />
        <Route path="/report" component={Report} exact />
        <Route exact path="/transaction" component={Transaction} />

        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
};

export default Root;
