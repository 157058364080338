import React, { Fragment, Component } from "react";
import Autocomplete from "react-autocomplete";

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectClass: "autoComplete-label-hide"
    };
  }
  handleOnChange = e => {
    this.props.onChangeClick(e);
    this.setState({ SelectClass: "autoComplete-label" });
  };

  render() {
    return (
      <Fragment>
        <div class="form-group">
          <Autocomplete
            value={this.props.value}
            inputProps={{
              id: this.props.inputId,
              name: this.props.inputId,
              type: "text"
            }}
            wrapperStyle={{ position: "relative", display: "block" }}
            items={this.props.items}
            getItemValue={this.props.getItemValue}
            // shouldItemRender={ this.matchUserData }
            onChange={(e) => this.handleOnChange}
            onSelect={this.props.onSelect}
            renderMenu={children => <div className="menu">{children}</div>}
            renderItem={this.props.renderItem}
          />
          <label for={this.props.inputId} className={this.state.SelectClass}>
            {this.props.labelText}
          </label>
        </div>
      </Fragment>
    );
  }
}

export default AutoComplete;
