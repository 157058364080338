import React, { Fragment, Component } from "react";
import toastr from "toastr";
import Header from "./../../components/Header/Header";
import Stats from "./ProcessingStats";
import Recon from "./Recon";
import Bank from "./Bank";
import Address from "./Address";
import Nomination from "./Nomination";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      display: "Processing",
      processingActive: "active",
      reconActive: "",
      bankActive: "",
      addressActive: "",
      nominationActive: ""
    };
  }

  componentDidMount = () => {};

  onRoleChange = roleObj => {
    toastr.success("Selected " + roleObj.role);
    this.setState({ role: roleObj });
  };

  OnClick = (report, e) => {
    e.preventDefault();
    this.setState({ display: report }, function() {
      switch (this.state.display) {
        case "Processing":
          this.setState({
            processingActive: "active",
            reconActive: "",
            bankActive: "",
            addressActive: "",
            nominationActive: ""
          });
          break;
        case "Recon":
          this.setState({
            processingActive: "",
            reconActive: "active",
            bankActive: "",
            addressActive: "",
            nominationActive: ""
          });
          break;
        case "Bank":
          this.setState({
            processingActive: "",
            reconActive: "",
            bankActive: "active",
            addressActive: "",
            nominationActive: ""
          });
          break;
        case "Address":
          this.setState({
            processingActive: "",
            reconActive: "",
            bankActive: "",
            addressActive: "active",
            nominationActive: ""
          });
          break;
        case "Nomination":
          this.setState({
            processingActive: "",
            reconActive: "",
            bankActive: "",
            addressActive: "",
            nominationActive: "active"
          });
          break;
        default:
          this.setState({
            processingActive: "active",
            reconActive: "",
            bankActive: "",
            addressActive: "",
            nominationActive: ""
          });
          break;
      }
    });
  };

  render() {
    const h = window.innerHeight;
    let report = <div />;
    if (!this.state.display) {
      report = <div>Select Report</div>;
    } else if (this.state.display === "Processing") {
      report = <Stats />;
    } else if (this.state.display === "Recon") {
      report = <Recon />;
    } else if (this.state.display === "Bank") {
      report = <Bank />;
    } else if (this.state.display === "Address") {
      report = <Address />;
    } else if (this.state.display === "Nomination") {
      report = <Nomination />;
    }

    return (
      <Fragment>
        <Header userRole="user" />
        <div
          id="report"
          className="container-fluid"
          style={{
            marginTop: h / 2 - (205 + 150),
            height: h
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <ul class="nav nav-pills">
                <li class={"processing " + this.state.processingActive}>
                  <button
                    onClick={this.OnClick.bind(this, "Processing")}
                    class="btn_link"
                  >
                    Processing Stats <i class="fa fa-bar-chart icon fa-1x" />
                  </button>
                </li>
                <li class={"recon " + this.state.reconActive}>
                  <button
                    onClick={this.OnClick.bind(this, "Recon")}
                    class="btn_link"
                  >
                    Recon <i class="fa fa-bar-chart icon fa-1x" />
                  </button>
                </li>
                <li class={"bank " + this.state.bankActive}>
                  <button
                    onClick={this.OnClick.bind(this, "Bank")}
                    class="btn_link"
                  >
                    Bank <i class="fa fa-university icon fa-1x" />
                  </button>
                </li>
                <li class={"address " + this.state.addressActive}>
                  <button
                    onClick={this.OnClick.bind(this, "Address")}
                    class="btn_link"
                  >
                    Address <i class="fa fa-address-book icon fa-1x" />
                  </button>
                </li>
                <li class={"nomination " + this.state.nominationActive}>
                  <button
                    onClick={this.OnClick.bind(this, "Nomination")}
                    class="btn_link"
                  >
                    Nomination <i class="fa fa-users icon fa-1x" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="rows">
            <div className="col-md-12">
              <h2>{this.state.display}</h2>
              {report}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Report;
