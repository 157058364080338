import React, { Component, Fragment } from "react";
import ReedemSchemeModal from "./ReedemSchemeModal";
import Button from '../../components/Button/Button';
import { roundNumber } from '../../utils/utils';

// import actions
import { getConsolidated, getLevelGains } from '../../actions/transactionActions';

const client = {
  "advisor_id": "ARN-110384",
  "client_id": "1081227",
  "sub_advisor_id": ""
};
class Redemption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      funds: [],
      fetching: true,
      server_error: '',
      showReedemModal: false,
    };
  }

  getInitialData = async () => {
    try {
      const firstResponse = await getConsolidated(client);
      const secondResponse = await getLevelGains(client);
      const consolidate = firstResponse.data;
      const level = secondResponse.data;
      consolidate.data.forEach(fund => {
        const curr_level = level.data.filter(item => item.product_code === fund.product_code);
        const short_term = curr_level.reduce((total, item) => total + item.short_term_gain, 0);
        const long_term = curr_level.reduce((total, item) => total + item.long_term_gain, 0);
        fund.short_term = roundNumber(short_term);
        fund.long_term = roundNumber(long_term);
        fund.isAmount = true;
      });
      this.setState({
        funds: consolidate.data,
        fetching: false,
      });

    } catch (err) {
      console.error('getInitialData, Server err: ', err.response);
      const data = err.response.data;
      this.setState({ server_error: data.error });
    }
  }

  componentDidMount() {
    this.getInitialData();
  }

  handleClose = () => {
    this.setState({ showReedemModal: false });
  }

  handleShow = () => {
    this.setState({ showReedemModal: true });
  }

  handleAmount = (index) => {
    const funds = this.state.funds;
    const fund = funds[index];
    fund.isAmount = !fund.isAmount;
    this.setState({ funds });
  }

  render() {
    const { funds, fetching } = this.state;
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="col al_card" id="al_redemption">

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-hover al_table">
                    <thead>
                      <tr>
                        <th scope="col">Scheme</th>
                        <th scope="col">Folio No</th>
                        <th scope="col">Units</th>
                        <th scope="col">Current Value</th>
                        <th scope="col">XIRR</th>
                        <th scope="col">Short Term</th>
                        <th scope="col">Long Term</th>
                        <th scope="col"><strong>Redeem</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      {fetching && (
                        <tr>
                          <td colSpan="20" className="text-center">loading...</td>
                        </tr>
                      )}
                      {!fetching && funds.length > 0 && (
                        funds.map((fund, index) => {
                          return (
                            <tr key={index}>
                              <td>{fund.scheme_name}</td>
                              <td>{fund.scheme_folio_no}</td>
                              <td>{fund.unit_balance}</td>
                              <td>{fund.current_value}</td>
                              <td>{fund.xirr}</td>
                              <td>{fund.short_term}</td>
                              <td>{fund.long_term}</td>
                              <td>
                                <div className="row no-gutters">
                                  <div className="form-check mr-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={'redemAmount' + index}
                                      id={'amount' + index}
                                      checked={fund.isAmount}
                                      onChange={() => this.handleAmount(index)}
                                    />
                                    <label className="form-check-label" htmlFor={'amount' + index} style={{ marginTop: '3px' }}>
                                      By Amount
                                    </label>
                                  </div>
                                  <div className="form-check mr-4">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={'redemAmount' + index}
                                      id={'units' + index}
                                      checked={!fund.isAmount}
                                      onChange={() => this.handleAmount(index)}
                                    />
                                    <label className="form-check-label" htmlFor={'units' + index} style={{ marginTop: '3px' }}>
                                      By Units
                                    </label>
                                  </div>
                                  <div className="table_input">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="amount"
                                      placeholder={fund.isAmount ? 'Amount' : 'Units'}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      )}

                    </tbody>
                  </table>
                </div>
                <ReedemSchemeModal
                  show={this.state.showReedemModal}
                  onHide={this.handleClose}
                />
              </div>
            </div>

            <div className="row justify-content-center mt-3">
              <div className="mr-4">
                <Button type="button" text="Transact Now" />
              </div>
              <Button type="button" text="Add to Cart" />
            </div>

            {/* <div className="row mt-5">
              <div className="al_sub_heading">3.Details</div>
            </div>
            <div className="row">
              <div className="col-md-11 offset-md-1">
                <div class="table-responsive">
                  <table className="table table-hover al_table">
                    <thead>
                      <tr>
                        <th scope="col">Scheme</th>
                        <th scope="col">Indicative Value</th>
                        <th scope="col">Redemption Amount</th>
                        <th scope="col">Redemption By Units</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Scheme Name dropdown has to be given here</td>
                        <td>1000000</td>
                        <td>1000</td>
                        <td>All</td>
                        <td>
                        <i class="fa fa-edit al_edit"></i>
                        <i class="fa fa-cart-plus al_cart"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </Fragment>
    )
  }
};

export default Redemption;
