import React, { Fragment, Component } from "react";
import { Redirect } from "react-router";
import Dropdown from "../../components/Dropdown/Dropdown";
import Modal from "react-bootstrap/Modal";

class NewAccountCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investorTypeStyle: "",
      investorOptStyle: "",
      accountTypeStyle: "",
      JointStyle: "",
      investorTypeValue: "",
      accountTypeValue: "",
      investorOptsValue: "0",
      jointValue: "0",
      displayinvestorOpts: false,
      displayAccountType: false,
      displayJoint: false,
      redirectToNewPage: false,
      investorType: [
        { value: "Resident Individual", id: 1 },
        { value: "Non - Resident Individual", id: 2 }
        /*  { value: "Corporate", id: 3 },
        { value: "Trust", id: 4 },
        { value: "Partnership Firm", id: 5 },
        { value: "HUF", id: 6 },
        { value: "Societies", id: 7 } */
      ],
      investorOpts: [{ value: "NRO", id: 1 }, { value: "NRE", id: 2 }],
      accountType: [
        { value: "Individual Investor", id: 1 },
        { value: "Joint ", id: 2 },
        { value: "Sole - Proprietor", id: 3 },
        { value: "Minor", id: 4 }
      ],
      joint: [{ value: "1", id: 1 }, { value: "2", id: 2 }]
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isRender !== this.props.isRender) {
      this.resetState();
    }
  }

  resetState = () => {
    this.setState({
      displayinvestorOpts: false,
      displayAccountType: false,
      displayJoint: false
    });
  };

  handleInvestorTypeClick = investorType => {
    let value = investorType.target.value;
    switch (value) {
      case "1":
        this.setState(
          {
            displayinvestorOpts: false
          },
          () => {
            this.setState({ investorTypeValue: value });
          }
        );
        this.setState(
          {
            displayAccountType: true
          },
          () => {
            this.setState({ investorTypeValue: value });
          }
        );
        break;
      case "2":
        this.setState(
          {
            displayinvestorOpts: true
          },
          () => {
            this.setState({ investorTypeValue: value });
          }
        );
        this.setState(
          {
            displayAccountType: true
          },
          () => {
            this.setState({ investorTypeValue: value });
          }
        );
        break;
      default:
        this.setState(
          {
            displayinvestorOpts: false
          },
          () => {
            this.setState({ investorTypeValue: value });
          }
        );
        this.setState(
          {
            displayAccountType: false
          },
          () => {
            this.setState({ investorTypeValue: value });
          }
        );
        break;
    }
    this.setState({ investorTypeStyle: "select-wrap" }, () => {});
  };

  handleAccountTypeClick = accountype => {
    let value = accountype.target.value;
    switch (value) {
      case "2":
        this.setState(
          {
            displayJoint: true
          },
          () => {
            this.setState({ accountTypeValue: value });
          }
        );
        break;
      default:
        this.setState(
          {
            displayJoint: false
          },
          () => {
            this.setState({ accountTypeValue: value });
          }
        );
        break;
    }
    this.setState({ accountTypeStyle: "select-wrap" }, () => {});
  };

  onInvestorOptClick = event => {
    const value = event.target.value;
    this.setState(
      {
        investorOptsValue: value
      },
      () => {
        this.setState({ investorOptStyle: "select-wrap" }, () => {});
      }
    );
  };

  onJointClick = event => {
    const value = event.target.value;
    this.setState(
      {
        jointValue: value
      },
      () => {
        this.setState({ jointStyle: "select-wrap" }, () => {});
      }
    );
  };

  componentDidMount() {};

  handleSubmit = e => {
    e.preventDefault();

    const accountTypeData = {
      investorType: this.state.investorTypeValue,
      accountType: this.state.accountTypeValue,
      opts: this.state.investorOptsValue,
      joint: this.state.jointValue,
      accountCreationType: this.props.title
    };
    console.log("accountTypeData:", accountTypeData);
    this.props.handleSubmit(e, accountTypeData);
  };

  render() {
    let investorOpts, joint, accountType;
    if (this.state.displayinvestorOpts) {
      investorOpts = (
        <Dropdown
          className={this.state.investorOptStyle}
          onOptionClick={this.onInvestorOptClick}
          default={"Investor Options"}
          selected={this.state.investorOpts.id}
          opts={this.state.investorOpts}
          field_attr="id"
          value_attr="value"
        />
      );
    }

    if (this.state.displayJoint) {
      joint = (
        <Dropdown
          className={this.state.jointStyle}
          onOptionClick={this.onJointClick}
          default={"Number of Joint Holders"}
          selected={this.state.joint.id}
          opts={this.state.joint}
          field_attr="id"
          value_attr="value"
        />
      );
    }
    if (this.state.displayAccountType) {
      accountType = (
        <div className="col-md-6">
          <Dropdown
            className={this.state.accountTypeStyle}
            onOptionClick={this.handleAccountTypeClick}
            default={"Account Type"}
            selected={this.state.accountType.id}
            opts={this.state.accountType}
            field_attr="id"
            value_attr="value"
          />
        </div>
      );
    }
    if (this.state.redirectToNewPage) {
      return <Redirect to="/kyc-search" />;
    }
    return (
      <Fragment>
        <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
          <Modal.Header closeButton className="al_modal">
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit}>
              <div id="investorModal" style={{ maxHeight: "400px", overflowY: "auto" }}>
                <div className="row modalrow">
                  <div className="col-md-6">
                    <Dropdown
                      className={this.state.investorTypeStyle}
                      onOptionClick={this.handleInvestorTypeClick}
                      default={"Investor Type"}
                      selected={this.state.investorType.id}
                      opts={this.state.investorType}
                      field_attr="id"
                      value_attr="value"
                    />
                  </div>
                  {accountType}
                </div>
                <div className="row modalrow">
                  <div className="col-md-6">{investorOpts}</div>
                  <div className="col-md-6">{joint}</div>
                </div>
              </div>
              <div className="row justify-content-center mt-5">
                <input
                  type="submit"
                  className="btn btn-info"
                  id="submit"
                  name="submit"
                  value="Submit"
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default NewAccountCreation;
