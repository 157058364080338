import axios from "axios";

// api calls
export const apiCalls = (method, url, data = {}) => {
  if (method === "get") {
    // only for get
    return axios({ method: method, url: url, params: data });
  } else {
    // post, put, delete
    return axios({ method: method, url: url, data: data });
  }
};


// Added axios global variable
// axios.interceptors.request.use(
//   config => {
//     const token = getUserToken();

//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }

//     return config;
//   },
//   error => Promise.reject(error)
// );
// axios.defaults.headers.post["Content-Type"] = "application/json";
