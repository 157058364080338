import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

const propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  text: "Button",
  type: "button",
  size: "md",
  onClick: () => { },
};

// how to use
// Ex1: <Button text="Add To Cart" />
// Ex2: <Button type="button" text="Add To Cart" onClick={this.addToCart} />

const Button = ({
  text,
  type,
  size,
  onClick
}) => (
  <button
    type={type}
    onClick={onClick}
    className={`btn btn-warning al_button ${'btn-'+size}`}
  >
    {text}
  </button>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
