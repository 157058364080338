import React, { Component, Fragment } from "react";
import Button from '../../components/Button/Button';

const clintList = [
  {
    bank: 'SBI',
    accountNo: 'SBIN123456789',
    amount: '1200',
    startDate: '18-04-2019'
  },
  {
    bank: 'HDFC',
    accountNo: 'HDFC123456789',
    amount: '1400',
    startDate: '18-04-2019'
  }
];
class Mandate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <div className="container" id="mandate">
          <h4>New Mandate</h4>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Amount</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Amount"
                />
              </div>
              <div className="form-group">
                <label>IFSC</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="IFSC"
                />
              </div>
              <div className="form-group">
                <label>Account Type</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account type"
                />
              </div>
              <div className="form-group">
                <label>Start date</label>
                <input
                  type="date"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Bank name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Bank name"
                />
              </div>
              <div className="form-group">
                <label>Account number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account number"
                />
              </div>
              <div className="form-group">
                <label>MICR</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="MICR"
                />
              </div>
              <div className="form-group">
                <label>End date</label>
                <input
                  type="date"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-3">
            <div>
              <Button type="button" text="Submit" />
            </div>
          </div>

          <h4 className="mt-5">Mandate List</h4>
          <div>
            <div className="table-responsive mt-4">
              <table className="table table-hover al_table">
                <thead>
                  <tr>
                    <th scope="col">Bank</th>
                    <th scope="col">Account No</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Start date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    clintList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.bank}</td>
                          <td>{item.accountNo}</td>
                          <td>{item.amount}</td>
                          <td>{item.startDate}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </Fragment>
    )
  }
};

export default Mandate;
