import React, { Component, Fragment } from "react";

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <div className="container">
          <div className="row pt-4 justify-content-center">
            WIP - History
          </div>
        </div>
      </Fragment>
    )
  }
};

export default History;
