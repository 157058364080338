
import React, { Component, Fragment } from "react";
import Modal from 'react-bootstrap/Modal';

class NewAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    return (
      <Fragment>
        <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
          <Modal.Header closeButton className="al_modal">
            <Modal.Title>New</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row justify-content-center mt-5">
              <button
                type="button"
                className="btn btn-info"
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    )
  }
};

export default NewAccountModal;
