import config from "../config";
import { apiCalls } from "./apiCalls";

export const panSearch = pan_number => {
  const url = config.base_url + `/investor/search`;

  const reqObject = {
    pan_number: pan_number
  };

  return apiCalls("post", url, reqObject);
};

export const nameSearch = name => {
  const url = config.base_url + `/investor/clients`;

  const reqObject = {
    advisor_id: "ARN-110384",
    sub_advisor_id: "",
    client_name: name
  };

  return apiCalls("post", url, reqObject);
};
