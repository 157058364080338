import React, { Fragment, Component } from "react";
import DataGrid from "../../components/DataGrid/DataGrid";
import { bankLoad } from "../../actions/reportsAction";

class Bank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      advisor: "ARN-110384",
      rowData: [],
      columnDefs: [
        { headerName: "Client_ID", field: "client_id", hide: true },
        { headerName: "Client", field: "client_name" },
        { headerName: "Account Info Id", field: "account_info_id", hide: true },
        { headerName: "Bank Name", field: "bank_name" },
        { headerName: "Account Number", field: "bank_ac_number" },
        { headerName: "IFSC Code", field: "bank_ifsc_code" }
      ]
    };
    // Bind the this context to the handler function
  }

  componentDidMount = () => {
    this.handleBankLoad();
  };

  onRowSelected(event) {}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  handleBankLoad = async () => {
    const err = "";
    if (!err) {
      const data = this.state.advisor;
      try {
        const clientDataRes = await bankLoad(data);
        const res = clientDataRes.data;
        if (res.status === "success" && res.data !== undefined) {
          this.setState({ rowData: res.data, fetching: true });
        }
      } catch (err) {
        console.error("handleBankLoad, Server err: ", err.response);
      }
    }
  };

  render() {
    const { fetching, rowData } = this.state;
    return (
      <Fragment>
        <div id="processing">
          {!fetching && <div className="text-center">loading...</div>}
          {fetching && rowData.length > 0 && (
            <DataGrid
              ref="idreviewgrid"
              RowDef={this.state.rowData}
              onRowSelected={this.onRowSelected.bind(this)}
              onGridReady={this.onGridReady.bind(this)}
              ColumnDef={this.state.columnDefs}
              paginationPageSize="9"
              rowmodeltype="pagination"
              pagination={true}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default Bank;
